
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        type_map: {
            type: Number,
            default: 0
        },
        coords: {
            type: Object,
            default: () => {}
        },
        data_type: {
            type: String,
            default: ''
        },
        map_field: {
            type: Object,
            default: () => {}
        }
    },  
    setup() {
         
    },
    methods: {
        setMap(x, y, field) {            
            if(field) {
                this.setEndPoint(x, y)
            } else {
                this.$emit('start-map', {x: x, y: y})
            }
        },
        setEndPoint(x, y) {
            this.$emit('end-point', {x: x, y: y})            
        }
    }
})
