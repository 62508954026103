import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "oil-seismic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.seismics, (seismic_line) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: seismic_line }, [
        _createElementVNode("div", {
          class: _normalizeClass(["oil-seismic__line", {_vertical: seismic_line.startX === seismic_line.endX && seismic_line.startX === _ctx.$props.coords.x, '_selected-tab': _ctx.data_type === 'seismic'}])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["oil-seismic__line", {_horizontal: seismic_line.startY === seismic_line.endY && seismic_line.startY === _ctx.$props.coords.y, '_selected-tab': _ctx.data_type === 'seismic'}])
        }, null, 2)
      ], 64))
    }), 128))
  ]))
}