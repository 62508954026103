import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_cmp = _resolveComponent("btn-cmp")!

  return (!_ctx.disable.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`${_ctx.$props.class}__buy-map`)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(`${_ctx.$props.class}__buy-map__title`)
        }, _toDisplayString(_ctx.$root.static_text.modal.buy_map.title), 3),
        _createElementVNode("span", {
          class: _normalizeClass(`${_ctx.$props.class}__buy-map__square`)
        }, _toDisplayString(_ctx.$root.static_text.modal.buy_map.square) + " " + _toDisplayString(_ctx.$props.data.map_length - 1) + " ячеек", 3),
        _createElementVNode("div", {
          class: _normalizeClass(`${_ctx.$props.class}__buy-map__table`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.$props.class}__buy-map__table__row _header`)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$root.static_text.table.cost_type), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$root.static_text.table.values), 1)
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table, (row) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(`${_ctx.$props.class}__buy-map__table__row _content`),
              key: row
            }, [
              _createElementVNode("span", null, _toDisplayString(row.name), 1),
              _createElementVNode("span", null, _toDisplayString(row.value), 1)
            ], 2))
          }), 128))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(`${_ctx.$props.class}__buy-map__table__btns`)
        }, [
          _createVNode(_component_btn_cmp, {
            text: _ctx.$root.static_text.buttons.cancel,
            background: false,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.undoBuildMap()))
          }, null, 8, ["text"]),
          _createVNode(_component_btn_cmp, {
            text: _ctx.$root.static_text.buttons.add,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveBuildMap())),
            class: _normalizeClass({_disable: !_ctx.map_length})
          }, null, 8, ["text", "class"])
        ], 2),
        (_ctx.$props.data.map_length - 1 > 64)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(`${_ctx.$props.class}__buy-map__error`)
            }, "Максимально допустипая площадь 64 ячейки", 2))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}