import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "oil-results oil-container" }
const _hoisted_2 = { class: "oil-results__menubar" }
const _hoisted_3 = {
  key: 0,
  class: "oil-results__wells"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "oil-results__horizontal"
}
const _hoisted_6 = { class: "oil-results__horizontal__name" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "oil-results__profile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menubar_cmp = _resolveComponent("menubar-cmp")!
  const _component_wells_cmp = _resolveComponent("wells-cmp")!
  const _component_highcharts = _resolveComponent("highcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return (_openBlock(), _createBlock(_component_menubar_cmp, {
          key: item.id,
          active: _ctx.active,
          id: item.id,
          text: item.name,
          onClick: ($event: any) => (_ctx.selectTabs(item.id)),
          class: "oil-results__menubar-item"
        }, null, 8, ["active", "id", "text", "onClick"]))
      }), 128))
    ]),
    _createVNode(_TransitionGroup, { name: "fadeRight" }, {
      default: _withCtx(() => [
        (_ctx.active === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.visible)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.result, (item) => {
                    return (_openBlock(), _createBlock(_component_wells_cmp, {
                      key: item,
                      type: item.type,
                      result: item.content,
                      onUpdate: _ctx.changeData
                    }, null, 8, ["type", "result", "onUpdate"]))
                  }), 128))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, "Вы ещё не проводили исследования"))
            ]))
          : _createCommentVNode("", true),
        (_ctx.active === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.chartOptions.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.chartOptions, (img) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: img }, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(img.name.text), 1),
                      _createElementVNode("img", {
                        src: img.imageLink
                      }, null, 8, _hoisted_7)
                    ], 64))
                  }), 128))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "Вы ещё не проводили сейсмику"))
            ]))
          : _createCommentVNode("", true),
        (_ctx.active === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartOptions, (chart) => {
                return (_openBlock(), _createBlock(_component_highcharts, {
                  key: chart,
                  options: chart
                }, null, 8, ["options"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}