
import { defineComponent, reactive, onMounted } from 'vue';
import menubarCmp from '@/components/ui-components/oil-menubar/menubar-cmp.vue';
import buttonCmp from '@/components/ui-components/oil-button/button-cmp.vue';
import radioCmp from '@/components/ui-components/oil-radio/radio-cmp.vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default defineComponent({
    setup() {
        const router = useRouter()

        const menubar_list = [
            {
                text: 'Скважины' as string,
                id: 1 as number
            },
            {
                text: 'Исследования' as string,
                id: 2 as number
            },
        ]

        const active_menubar = reactive({
            menu_id: 1 as number
        })

        const borehole_statistic_table = reactive({
            borehole_table: [] as any,
            main_research_table: []
        })

        const borehole_active = reactive({
            borehole_id: 0
        })

        const active_subrow = reactive({
            value: null as number | null
        })

        const table_template = [ //delete
            {
                id: 0,
                boreholeName: {
                    text: "Скважина"
                },
                boreholeStatus: {
                    text: "Статус"
                },
                capex: {
                    text: "Capex"
                },
                accumulatedOpex: {
                    text: "OPEX (накопленный)"
                },
                currentOpex: {
                    text: "OPEX (текущий)"
                },
                tu: {
                    text: 'TU'
                },
                wfu: {
                    text: 'WFU'
                },
                eu: {
                    text: 'EU'
                }
            },
            {
                id: 1,
                boreholeName: {
                    text: "P1 (Разведывательная)",
                    coords: {
                        cell:{
                            x:1,
                            y:1,
                        },
                        region:{
                            x:1,
                            y:1,
                        },
                    }
                },
                boreholeStatus: {
                    text: "Исследование",
                    color: "#DCFFF0"
                },
                capex: {
                    text: "-25000"
                },
                accumulatedOpex: {
                    text: "-25000"
                },
                currentOpex: {
                    text: "-25000"
                },
                tu: {
                    text: '50'
                },
                wfu: {
                    text: '-31'
                },
                eu: {
                    text: '-31'
                }
            }
        ]

        const research_template = [
            {
                boreholeResearch: {
                    text: "Исследование"
                },
                boreholeMark: {
                    text: "Обозначение",
                },
                boreholeCountResearch: {
                    text: "Кол-во проведенных исследований"
                },
            },
            {
                researchName: "ГИС (Геофизические исследования)",
                researches: [
                    {
                        boreholeResearch: {
                            text: "Гамма-каротаж"
                        },
                        boreholeMark: {
                            text: "GR",
                            color: "#5581D6"
                        },
                        boreholeCountResearch: {
                            text: "5"
                        },
                    },
                    {
                        boreholeResearch: {
                            text: "Гамма-каротаж"
                        },
                        boreholeMark: {
                            text: "GR",
                            color: "#5581D6"
                        },
                        boreholeCountResearch: {
                            text: "5"
                        },
                    },
                    
                ]
                
            },
        ]

        const open_subrow = reactive({
            id: 0
        })

        const openSubRow = (sub_row_id: number) => {
            open_subrow.id = open_subrow.id === sub_row_id ? 0 : sub_row_id
        }

        const selectMenu = (selected_menu: number) => {
            active_menubar.menu_id = selected_menu
        }

        const setBoreholeStatistic = (state_option) => {
            borehole_active.borehole_id = state_option
        }

        const setResearchSubrow = (idx: number) => {            
            active_subrow.value = active_subrow.value === null ? idx : null 
        }

        const openStatistic = () => {
            router.push({ 
                path: `statistic/${borehole_active.borehole_id}`,
                query: { borehole_name: borehole_statistic_table.borehole_table.find(borehole => borehole.id === borehole_active.borehole_id)?.boreholeName.text }
            });
        }

        onMounted(() => {
            axios
                .get('Views/Browser/Statistic/Borehole/MainPage')
                .then(response => {
                    borehole_statistic_table.borehole_table = response.data
                })

            axios 
                .get(`Views/Browser/Statistic/Borehole/LogName`)
                .then(response => {
                    borehole_statistic_table.main_research_table = response.data
                })               
        })

        return {
            menubar_list,
            active_menubar,
            table_template,
            borehole_statistic_table,
            borehole_active,
            open_subrow,
            research_template,
            active_subrow,
            selectMenu,
            openSubRow,
            openStatistic,
            setBoreholeStatistic,
            setResearchSubrow
        }
    },
    components: {
        'menubar-cmp': menubarCmp,
        'btn-cmp': buttonCmp,
        'radio-cmp': radioCmp,
    }
})

