
import buttonCmp from '@/components/ui-components/oil-button/button-cmp.vue';
import oilSelectInput from '@/components/ui-components/oil-select-input/oil-select-input.vue';
import menubarCmp from '@/components/ui-components/oil-menubar/menubar-cmp.vue';
import { defineComponent, onMounted, reactive, watch } from 'vue';
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        const route = useRoute()
        const store = useStore()
        const router = useRouter()
        
        const menubar = reactive({
            active: 1,
            menu: [
                {
                    text: 'Общая информация',
                    id: 1
                },
                {
                    text: 'Затраты',
                    id: 2
                },
                {
                    text: 'Добыча',
                    id: 3
                },
            ]
        })

        const borehole_info = reactive({
            table_general_data: {
                header: [],
                content: {
                    content: {
                        coordinate: {
                            value: {
                                region: {},
                                cell: {}
                            },
                            parametr: ""
                        },
                        date: {
                            value: {
                                date: {}
                            }
                        },
                        md: {
                            parametr: ""
                        },
                        tvd: {
                            parametr: ""
                        },
                        type: {
                            parametr: ""
                        }
                    }
                }
            },
            table_expenses_data: [] as any,
            table_extraction_data: [] as any,
            borehole_list: [] as any
        })

        const extracted_resources_icons = [
            {
                icon: ''
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0003 17.9167C11.5474 17.9167 13.0312 17.3021 14.1251 16.2082C15.2191 15.1142 15.8337 13.6305 15.8337 12.0834C15.8337 10.4167 15.0003 8.83337 13.3337 7.50004C11.667 6.16671 10.417 4.16671 10.0003 2.08337C9.58366 4.16671 8.33366 6.16671 6.66699 7.50004C5.00033 8.83337 4.16699 10.4167 4.16699 12.0834C4.16699 13.6305 4.78157 15.1142 5.87554 16.2082C6.9695 17.3021 8.45323 17.9167 10.0003 17.9167Z" fill="#905CA9" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 15C17.5 15 16.508 14.6087 15.8333 14.4183C11.5664 13.2145 8.43357 16.7855 4.16667 15.5816C3.49201 15.3912 2.5 15 2.5 15M17.5 9.99996C17.5 9.99996 16.508 9.60869 15.8333 9.41834C11.5664 8.21445 8.43357 11.7855 4.16667 10.5816C3.49201 10.3912 2.5 9.99996 2.5 9.99996M17.5 4.99996C17.5 4.99996 16.508 4.60869 15.8333 4.41834C11.5664 3.21445 8.43357 6.78547 4.16667 5.58158C3.49201 5.39122 2.5 4.99996 2.5 4.99996" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4667 5.06671C11.0398 4.15098 11.4461 3.14091 11.6667 2.08337C12.0833 4.16671 13.3333 6.16671 15 7.50004C16.6667 8.83337 17.5 10.4167 17.5 12.0834C17.5048 13.2353 17.1674 14.3627 16.5307 15.3226C15.8939 16.2826 14.9865 17.0318 13.9234 17.4754C12.8604 17.9191 11.6895 18.037 10.5593 17.8144C9.42911 17.5918 8.39044 17.0387 7.575 16.225M5.83333 13.15C7.66667 13.15 9.16667 11.625 9.16667 9.77504C9.16667 8.80837 8.69167 7.89171 7.74167 7.11671C6.79167 6.34171 6.075 5.19171 5.83333 3.98337C5.59167 5.19171 4.88333 6.35004 3.925 7.11671C2.96667 7.88337 2.5 8.81671 2.5 9.77504C2.5 11.625 4 13.15 5.83333 13.15Z" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0003 17.9167C11.5474 17.9167 13.0312 17.3021 14.1251 16.2082C15.2191 15.1142 15.8337 13.6305 15.8337 12.0834C15.8337 10.4167 15.0003 8.83337 13.3337 7.50004C11.667 6.16671 10.417 4.16671 10.0003 2.08337C9.58366 4.16671 8.33366 6.16671 6.66699 7.50004C5.00033 8.83337 4.16699 10.4167 4.16699 12.0834C4.16699 13.6305 4.78157 15.1142 5.87554 16.2082C6.9695 17.3021 8.45323 17.9167 10.0003 17.9167Z" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            }
        ]

        const endpoints = [
            {
                url: `Views/Browser/Map/Borehole/generalInformation/${route.params.id}`
            },
            {
                url: `Views/Browser/Map/Borehole/Expenses/${route.params.id}`
            },
            {
                url: `Views/Browser/Map/Borehole/Extraction/${route.params.id}`
            },
            {
                url: 'Views/Browser/Map/Borehole/Info'
            }
        ] as Array<{
            url: string
        }>

        const selectMenu = (menu_id: number) => {
            menubar.active = menu_id 
        }

        const openned_row = reactive({
            secondary_row: 0,
            third_row: 0
        })

        const active_borehole = reactive({
            id: 0,
            borehole: ''
        })

        const setBoreholeId = (val) => {
            active_borehole.id = val
            router.push(`${val}`)
        }

        const openRow = (row_id) => {            
            if(openned_row.secondary_row === row_id) {
                openned_row.third_row = 0
                openned_row.secondary_row = 0
            } else {
                openned_row.secondary_row = row_id
            }
        }

        const openThirdRow = (row_id) => {
            openned_row.third_row = openned_row.third_row === row_id ? 0 : row_id
        }

        watch(() => active_borehole.id, () => {
            axios.all(endpoints.map((endpoint) => axios.get(endpoint.url)))
                .then(axios.spread((general_response, expenses_response, extraction_response, borehole_response) => {
                        console.log(general_response.data , 'axios');
                        
                        borehole_info.table_general_data = general_response.data 
                        borehole_info.table_expenses_data = expenses_response.data 
                        borehole_info.table_extraction_data = extraction_response.data  
                        borehole_info.borehole_list = borehole_response.data.items
                    }) 
            )
            .catch(err => {
                store.commit('notification/openNotification', {
                    error: true,
                    title: 'Ошибка!',
                    desc: err.response.data
                })
            })
            .finally(() => {
                console.log(borehole_info.table_general_data, 'setup');
                borehole_info.table_expenses_data = borehole_info.table_expenses_data.header.content?.concat( borehole_info.table_expenses_data.content?.content)
                borehole_info.table_extraction_data = borehole_info.table_extraction_data.header.content?.concat( borehole_info.table_extraction_data.content?.content)

                borehole_info.table_extraction_data = borehole_info.table_extraction_data.map((row, idx) => 
                ({
                    ...row,
                    ...extracted_resources_icons[idx]
                }))

                active_borehole.borehole = borehole_info.borehole_list.find(borehole => borehole.id === Number(route.params.id)).name
            })
        })

        onMounted(() => {
            axios.all(endpoints.map((endpoint) => axios.get(endpoint.url)))
                .then(axios.spread((general_response, expenses_response, extraction_response, borehole_response) => {
                        console.log(general_response.data , 'axios');
                        
                        borehole_info.table_general_data = general_response.data 
                        borehole_info.table_expenses_data = expenses_response.data 
                        borehole_info.table_extraction_data = extraction_response.data  
                        borehole_info.borehole_list = borehole_response.data.items
                    }) 
            )
            .catch(err => {
                store.commit('notification/openNotification', {
                    error: true,
                    title: 'Ошибка!',
                    desc: err.response.data
                })
            })
            .finally(() => {
                console.log(borehole_info.table_general_data, 'setup');
                borehole_info.table_expenses_data = borehole_info.table_expenses_data.header.content?.concat( borehole_info.table_expenses_data.content?.content)
                borehole_info.table_extraction_data = borehole_info.table_extraction_data.header.content?.concat( borehole_info.table_extraction_data.content?.content)

                borehole_info.table_extraction_data = borehole_info.table_extraction_data.map((row, idx) => 
                ({
                    ...row,
                    ...extracted_resources_icons[idx]
                }))

                active_borehole.borehole = borehole_info.borehole_list.find(borehole => borehole.id === Number(route.params.id)).name
            })
        })

        return {
            menubar,
            borehole_info,
            selectMenu,
            openned_row,
            setBoreholeId,
            active_borehole,
            openThirdRow,
            openRow
        }
    },
    components: {
        'btn-cmp': buttonCmp,
        'selector-cmp': oilSelectInput,
        'menubar-cmp': menubarCmp
    }
})
