
import { defineComponent, reactive, computed } from 'vue'
import { useStore, mapMutations } from 'vuex'
import axios from 'axios'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import checkboxCmp from '../../../ui-components/oil-checkbox/checkbox-cmp.vue'

export default defineComponent ({
    props: {
        data: {
            type: Array,
            default: () => []
        },
        main_class: {
            type: String,
        }
    },
    setup() {
        const text = reactive({
            points: [
                {
                    text: 'Выберите вид 3D-модели для покупки.'
                },
                {
                    text: 'После того как 3D-модель будет готова, она станет доступной для просмотра во вкладке “',
                    link: {
                        text: '3D-модель',
                        url: '/scene',
                        class: 'link'
                    },
                    tail: '”.'
                },
                {
                    text: 'Обратите внимание, некоторые из свойств являются динамическими.'
                },
                {
                    text: 'Каждое свойство можно приобрести один раз за ход.'
                }
            ]
        })
        
        const TD_models = reactive({
            value: [] as Array<{
                text: string,
                isAvailable: boolean,
                propertyId: number,
                oc: number,
                tu: number
            }>,
            selected_value: [] as any
        })

        const has_checked_models = computed(() => {
            return TD_models.selected_value.some(model => model.checked);
        })

        const store = useStore()

        const closeModal = () => {
            store.commit('modal/closeModal')
        }

        const addModelModal = () => {

            store.commit('modal/openModal', {
                newState: 'addModelModal',
                props: {
                    data: TD_models.selected_value.filter(model => model.checked),
                    prev_modal: '3d_model'
                }
            })
        }

        const createArray3dmodel = (obj) => {
            if(obj.checkbox_state) {
                TD_models.selected_value.find(item => item.text === obj.checkbox_type).checked = true
            } else {
                TD_models.selected_value.find(item => item.text === obj.checkbox_type).checked = false
            }
        }

        axios
            .get('Views/Browser/Map/Purchase/ModelProperty')
            .then(response => {
                TD_models.value = response.data
                TD_models.selected_value = TD_models.value.map(item => ({
                    text: item.text, 
                    checked: false, 
                    propertyId: item.propertyId,
                    oc: item.oc,
                    tu: item.tu
                }))
            })
            .catch(error => {
                console.error('Ошибка при загрузке данных:', error)
            })

        return {
            closeModal,
            text,
            TD_models,
            addModelModal,
            createArray3dmodel,
            has_checked_models
        }
    },
    components: {
        'button-cmp': buttonCmp,
        'checkbox-cmp': checkboxCmp
    },
    methods: {
        ...mapMutations('modal', ['openModal'])
    }
})
