<template>
    <div :class="`${main_class}__edit-interval`">
        <h2 :class="`${main_class}__edit-interval__title`">{{ data.modal_name ? 'Редактирование интервала' : 'Просмотр интервала' }}</h2>
        <div :class="`${main_class}__edit-interval__inputs`">
            <input-cmp 
                v-for="(input, idx) in edit_interval.value"
                :class="[idx < 2 ? '_disable' : '', `${main_class}__edit-interval__inputs__input`]"
                :key="input.type"
                :label="input.label"
                :type="input.type"
                :initValue="input.init_value"
                @set="setValue"
            />
        </div>
        <div :class="`${main_class}__edit-interval__container`" v-if="data.checkbox_visible">
            <checkbox-cmp 
                :class="`${main_class}__edit-interval__checkbox`"
                :text="'Установить насос типа ЭЦН'"
                :activeCheckbox="data.checkbox_active"
            />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @mouseenter="visible_tooltip.value = true" @mouseleave="visible_tooltip.value = false">
                <path d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <transition name="fade">
                <div :class="`${main_class}__edit-interval__container__tooltip`" v-if="visible_tooltip.value === true">
                    <span>Характеристики насоса определятся автоматически, основываясь на глубине спуска и свойствах жидкости</span>
                </div>
            </transition>
        </div>
        <span :class="`${main_class}__edit-interval__attention`" v-if="data.interval_type === 'Эксплуатационная колонна'">Интервалы перфораций задаются во вкладке “ГТМ”.</span>
        <span :class="`${main_class}__edit-interval__attention`" v-if="data.interval_type === 'Насосно-компрессорная труба (НКТ)'">Обратите внимание, глубина спуска НКТ включает в себя глубину НКТ и насоса типа ЭЦН.</span>
        <div :class="`${main_class}__edit-interval__btns`">
            <button-cmp 
                :text="'Отменить'"
                :background="false"
                @click="closeModal"
            />
            <button-cmp 
                :text="'Сохранить'"
                :background="true"
                @click="saveInterval"
                :class="{_disable: !edit_interval.value[2].value.length}"
            />
        </div>
    </div>
</template>
<script>
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import inputCmp from '../../../ui-components/oil-input/input-cmp.vue'
import checkboxCmp from '../../../ui-components/oil-checkbox/checkbox-cmp.vue'

import { mapActions, mapMutations } from 'vuex'
import { reactive } from 'vue'
import { useStore } from 'vuex'

import axios from 'axios'


export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        main_class: {
            type: String,
        }
    },
    setup(props) {
        // const root = getCurrentInstance().root

        // console.log(root);
        const store = useStore();

        const closeModal = () => {
            store.commit('modal/closeModal')
        }

        const visible_tooltip = reactive({
            value: false
        })

        const edit_interval = reactive({
            value: [
                {
                    label: 'Тип интервала',
                    type: 'interval',
                    value: '',
                    init_value: props.data.interval_type
                },
                {
                    label: 'Диаметр НКТ, mm',
                    type: 'diametr',
                    value: '',
                    init_value: props.data.diameter
                },
                {
                    label: 'Глубина MD, m',
                    type: 'md',
                    value: '',
                    init_value: props.data.md
                },
            ]
        })

        const saveInterval = () => {
            axios
                .patch(`api/v1/drilling/interval/${props.data.id_interval}`,{
                    MdInMeters: edit_interval.value[2].value,
                    IsPumpSet: null
                })
                .then((resp) => {
                    console.log(resp);
                })
                .finally(() => {
                    closeModal()
                })
                .catch((err) => {
                    store.commit('notification/openNotification',{
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const setValue = (val) => {
            for (let i = 0; i < edit_interval.value.length; i++) {
                if (edit_interval.value[i].type === val.type) {
                    edit_interval.value[i].value = val.value;
                }
            }
        }

        return {
            edit_interval,
            closeModal,
            visible_tooltip,
            setValue,
            saveInterval
        }
    },
    components: {
        'button-cmp': buttonCmp,
        'input-cmp': inputCmp,
        'checkbox-cmp': checkboxCmp,
    },
    methods: {
        ...mapActions('request', ['setObject']),
        ...mapMutations('request', ['updateMap']),
        ...mapMutations('notification', ['openNotification']),
        },
    }
</script>
