
    import { defineComponent, reactive } from 'vue';
    import inputCmp from '../../../ui-components/oil-input/input-cmp.vue'
    import checkboxCmp from '../../../ui-components/oil-checkbox/checkbox-cmp.vue'
    import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
    import { useStore } from 'vuex';

    export default defineComponent({
        props: {
            data: {
                type: Object,
                default: () => {}
            },
            main_class: {
                type: String,
            }
        },
        setup() {
            const store = useStore()

            const login_form = reactive({})

            const remember_me = reactive({
                value: false
            })

            const form_authorization = reactive([
                {
                    id: "userName",
                    value: "",
                    type: "email",
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Email",
                    error: ""
                },
                {
                    id: "password",
                    value: "",
                    type: "password",
                    pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                    label: "Пароль",
                    error: ""
                },
            ])

            const visiblePassword = (type: string) => {
                if (type === "password") {
                    form_authorization[1].type = 'text';
                } else {
                    form_authorization[1].type = 'password';
                }
            }

            const setUserData = (user_data) => {
                for (let i = 0; i < form_authorization.length; i++) {
                    if (form_authorization[i].type === user_data.type) {
                        form_authorization[i].value = user_data.value;
                    }
                }
            }

            const openRestoreModal = () => {
                store.commit('modal/openModal', {
                    newState: 'restoreModal'
                })
            }

            const setRemember = (val) => {
                remember_me.value = val.checkbox_state
            }


            return {
                setRemember,
                setUserData,
                form_authorization,
                remember_me,
                visiblePassword,
                openRestoreModal,
                login_form
            }
        },
        components: {
            'input-cmp': inputCmp,
            'checkbox-cmp': checkboxCmp,
            'button-cmp': buttonCmp
        },
        methods: {
            loginSite() {
                for(let input_field in this.form_authorization) {
                    this.login_form[this.form_authorization[input_field].id] = this.form_authorization[input_field].value                    
                }                
                
                this.$root.getToken(this.login_form, {save_auth: this.remember_me.value})
            },
        },
    })

