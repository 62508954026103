
    import { defineComponent } from 'vue';
    import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
    import { mapMutations } from 'vuex';

    export default defineComponent({
        props: {
            main_class: {
                type: String,
            }
        },
        setup() {
        
        },
        components: {
            'button-cmp': buttonCmp
        },
        methods: {
            ...mapMutations('modal', ['openModal']),
        }
    })

