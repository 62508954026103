export default {
    namespaced: true,
    state: {
        notificationProps: null,
    },
    getters: {
        notificationProps(state) {
            return state.notificationProps;
        },
    },
    mutations: {
        openNotification(state, data) {            
            state.notificationProps = data
        },
        closeNotification(state) {
            state.notificationProps = null
        }
    },
    
}