<template>
    <div class="oil-wells">
        <div class="oil-wells__header" v-if="type === 'header'">
            <div class="oil-wells__name _cell" >
                <span class="oil-wells__name-cell">{{ result.boreholeName }}</span>
                <span class="oil-wells__name-cell">Статус скважины</span>
            </div>
        </div>
        <div class="oil-wells__content" v-if="type === 'content'">
            <div class="oil-wells__content-name" v-for="item in result" :key="item">
                <div class="oil-wells__content-container__wrapper">
                    <div class="oil-wells__content-container">
                        <span class="oil-wells__name-cell">{{ item.boreholeName }}</span>
                        <span class="oil-wells__name-coords">{{ item.coordinate }}</span>
                    </div>
                    <div class="oil-wells__content-container">
                        <div class="oil-wells__content-container__values">
                            <span class="oil-table__content__row-name__values__status" :style="{background: item.boreholeStatusColor}">
                                {{ item.boreholeStatusName }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="oil-wells__content-container__wrapper">
                    <div class="oil-wells__content-name-icon" @mouseenter="research = item.boreholeLogAvailable" @mouseleave="research = false" :class="{_disable: !item.boreholeLogAvailable}">
                        <svg  @click="$router.push(`graphics/${item.id}`)" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M3.5 22H12.5M11.5 6.25204C12.1392 6.08751 12.8094 6 13.5 6C17.9183 6 21.5 9.58172 21.5 14C21.5 17.3574 19.4318 20.2317 16.5 21.4185M6 13H10C10.4647 13 10.697 13 10.8902 13.0384C11.6836 13.1962 12.3038 13.8164 12.4616 14.6098C12.5 14.803 12.5 15.0353 12.5 15.5C12.5 15.9647 12.5 16.197 12.4616 16.3902C12.3038 17.1836 11.6836 17.8038 10.8902 17.9616C10.697 18 10.4647 18 10 18H6C5.53534 18 5.30302 18 5.10982 17.9616C4.31644 17.8038 3.69624 17.1836 3.53843 16.3902C3.5 16.197 3.5 15.9647 3.5 15.5C3.5 15.0353 3.5 14.803 3.53843 14.6098C3.69624 13.8164 4.31644 13.1962 5.10982 13.0384C5.30302 13 5.53534 13 6 13ZM4.5 5.5V13H11.5V5.5C11.5 3.567 9.933 2 8 2C6.067 2 4.5 3.567 4.5 5.5Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <transition name="fade">
                            <div v-if="research === item.boreholeLogAvailable" class="oil-wells__name-cell-tooltip">
                                <span>Результаты исследований</span>
                            </div>
                        </transition>
                    </div>
                    <div class="oil-wells__content-name-icon" @mouseenter="visibleKaratoje = item.boreholeName" @mouseleave="visibleKaratoje = false" :class="{_disable: !item.fileAvailable}">
                        <svg  @click="getLogging(item.id, item.boreholeName)" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 17.5H4.33333C3.86662 17.5 3.63327 17.5 3.45501 17.4092C3.29821 17.3293 3.17072 17.2018 3.09083 17.045C3 16.8667 3 16.6334 3 16.1667V2.5M17.1667 6.66667L13.9009 10.1522C13.7772 10.2843 13.7153 10.3504 13.6406 10.3845C13.5747 10.4146 13.5021 10.4271 13.43 10.4206C13.3482 10.4132 13.2679 10.3715 13.1072 10.2881L10.3928 8.8786C10.2321 8.79519 10.1518 8.75348 10.07 8.74611C9.99787 8.73961 9.92526 8.75204 9.85936 8.78217C9.78471 8.81631 9.72282 8.88236 9.59906 9.01445L6.33333 12.5" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <transition name="fade">
                            <div v-if="visibleKaratoje === item.boreholeName" class="oil-wells__name-cell-tooltip">
                                <span>Выгрузить каротажи</span>
                            </div>
                        </transition>
                    </div>
                    <div class="oil-wells__content-name-icon" @mouseenter="kern = item.boreholeName" @mouseleave="kern = false" :class="{_disable: !item.photoAvailable}">
                        <svg @click="getKern(item.id, item.boreholeName)" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.16602 6.98104C2.16602 6.68911 2.16602 6.54314 2.1782 6.4202C2.29568 5.23441 3.23374 4.29635 4.41952 4.17887C4.54247 4.16669 4.69631 4.16669 5.004 4.16669C5.12256 4.16669 5.18184 4.16669 5.23217 4.16364C5.87486 4.12472 6.43764 3.71908 6.6778 3.12169C6.69661 3.0749 6.71419 3.02216 6.74935 2.91669C6.78451 2.81121 6.80209 2.75847 6.8209 2.71169C7.06106 2.11429 7.62384 1.70866 8.26653 1.66974C8.31686 1.66669 8.37245 1.66669 8.48363 1.66669H12.5151C12.6262 1.66669 12.6818 1.66669 12.7322 1.66974C13.3749 1.70866 13.9376 2.11429 14.1778 2.71169C14.1966 2.75847 14.2142 2.81121 14.2493 2.91669C14.2845 3.02216 14.3021 3.0749 14.3209 3.12169C14.5611 3.71908 15.1238 4.12472 15.7665 4.16364C15.8169 4.16669 15.8761 4.16669 15.9947 4.16669C16.3024 4.16669 16.4562 4.16669 16.5792 4.17887C17.765 4.29635 18.703 5.23441 18.8205 6.4202C18.8327 6.54314 18.8327 6.68911 18.8327 6.98104V13.5C18.8327 14.9002 18.8327 15.6002 18.5602 16.135C18.3205 16.6054 17.9381 16.9879 17.4677 17.2275C16.9329 17.5 16.2328 17.5 14.8327 17.5H6.16602C4.76588 17.5 4.06582 17.5 3.53104 17.2275C3.06063 16.9879 2.67818 16.6054 2.4385 16.135C2.16602 15.6002 2.16602 14.9002 2.16602 13.5V6.98104Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.4993 13.75C12.3403 13.75 13.8327 12.2576 13.8327 10.4167C13.8327 8.57574 12.3403 7.08335 10.4993 7.08335C8.6584 7.08335 7.16602 8.57574 7.16602 10.4167C7.16602 12.2576 8.6584 13.75 10.4993 13.75Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <transition name="fade">
                            <div v-if="kern === item.boreholeName" class="oil-wells__name-cell-tooltip">
                                <span>Получить фото керна</span>
                            </div>
                        </transition>
                    </div>
                    <!-- <div class="oil-wells__content-name-icon" @mouseenter="levels = item.boreholeName" @mouseleave="levels = false" :class="{_disable: !item.boreholeLogAvailable}">
                        <svg @click="$router.push(`graphics/${item.id}`)" title="Отбивка уровней" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.16667 10.8333V14.1667M13.8333 9.16667V14.1667M10.5 5.83333V14.1667M7 17.5H14C15.4001 17.5 16.1002 17.5 16.635 17.2275C17.1054 16.9878 17.4878 16.6054 17.7275 16.135C18 15.6002 18 14.9001 18 13.5V6.5C18 5.09987 18 4.3998 17.7275 3.86502C17.4878 3.39462 17.1054 3.01217 16.635 2.77248C16.1002 2.5 15.4001 2.5 14 2.5H7C5.59987 2.5 4.8998 2.5 4.36502 2.77248C3.89462 3.01217 3.51217 3.39462 3.27248 3.86502C3 4.3998 3 5.09987 3 6.5V13.5C3 14.9001 3 15.6002 3.27248 16.135C3.51217 16.6054 3.89462 16.9878 4.36502 17.2275C4.8998 17.5 5.59987 17.5 7 17.5Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <transition name="fade">
                            <div v-if="levels === item.boreholeName" class="oil-wells__name-cell-tooltip">
                                <span>Отбивка уровней</span>
                            </div>
                        </transition>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import {mapState} from 'vuex'

    export default {
        props: {
            result: {
                type: Object,
                default: () => {}
            },
            type: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                update: false,
                visibleKaratoje: false,
                kern: false,
                levels: false,
                research: false,
            }
        },
        computed: {
            ...mapState('request', ['host'])
        },
        methods: {
            deleteItem(id) {
                axios
                    .delete(`${this.host}api/v1/Purchased/Borehole/${id}`)
                
                this.update = true
                this.$emit('update', this.update)
                setTimeout(() => {
                    this.update = false
                    this.$emit('update', this.update)
                }, 500);
            },
            getKern(id, name) {
                axios 
                    .get(`api/v1/LithologicalData/BoreholeZip/${id}`,
                        { responseType: 'blob' })
                        
                    .then(resp => {
                        const href = URL.createObjectURL(resp.data)
                        const link = document.createElement('a')
                        link.href = href 
                        link.setAttribute('download', `${name}.zip`)
                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    })
                    .catch(err => console.log(err))
            },
            getLogging(id, name) {
                axios 
                    .get(`api/v1/LithologicalData/ExportBoreholeLogLas/${id}`,
                        { responseType: 'blob' })
                        
                    .then(resp => {
                        const href = URL.createObjectURL(resp.data)
                        const link = document.createElement('a')
                        link.href = href 
                        link.setAttribute('download', `${name}.zip`)
                        document.body.appendChild(link)
                        link.click()

                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    })
                    .catch(() => this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: 'Что-то пошло не так, попробуйте позже'
                            }
                        }))
            }
        },
    }
</script>
