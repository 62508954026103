import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (count_lines) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["oil-communication", {'_selected-tab': _ctx.data_type === 'communication'}]),
      key: count_lines
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(count_lines.serviceLines, (line, idx) => {
        return (_openBlock(), _createElementBlock("div", { key: line }, [
          (!count_lines.segment)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["oil-communication__line", {
                    '_vertical-direction': count_lines.direction === 'Vertical', 
                    '_horizontal-direction': count_lines.direction === 'Horizontal', 
                    '_end-line': (count_lines.direction === 'Vertical' && count_lines.y === 25) || (count_lines.direction === 'Horizontal' && count_lines.x === 25),
                }]),
                style: _normalizeStyle({ 
                    background: (_ctx.coords.x === 1 && _ctx.coords.y === 1 && idx === 0) ? '#7C6539' : line.color, 
                    top: count_lines.direction === 'Vertical' ? `calc(-28px + ${(idx) * 5 - 3}px)` : `${(idx + 1) * 4 - 2}px`, 
                    left: `${(idx + 1) * 4 - 2}px`
                })
              }, null, 6))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 2))
  }), 128))
}