import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_cmp = _resolveComponent("checkbox-cmp")!
  const _component_btn_cmp = _resolveComponent("btn-cmp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.main_class}__choose-map`)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.main_class}__choose-map__title`)
    }, _toDisplayString(_ctx.$root.static_text.modal.buy_map.title), 3),
    _createElementVNode("p", {
      class: _normalizeClass(`${_ctx.main_class}__choose-map__desc`)
    }, "Выберите вид карты для покупки.", 2),
    _createElementVNode("p", {
      class: _normalizeClass(`${_ctx.main_class}__choose-map__desc`)
    }, "После того как карта будет готова её можно будет отобразить на лицензионном участке с помощью выбора карты через шестерёнку.", 2),
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.main_class}__choose-map__subtitle`)
    }, "Вид карты", 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__choose-map__checkboxs`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maps_data.value, (map) => {
        return (_openBlock(), _createBlock(_component_checkbox_cmp, {
          key: map.id,
          class: _normalizeClass([`${_ctx.main_class}__choose-map__checkboxs_checkbox`, {_disabled: map.isPurchased}]),
          text: map.description,
          type_checkbox: map.description,
          id: map.id,
          active: _ctx.active_type_map.value,
          onSetObjCheckbox: _ctx.createArrayMaps
        }, null, 8, ["class", "text", "type_checkbox", "id", "active", "onSetObjCheckbox"]))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__choose-map__btns`)
    }, [
      _createVNode(_component_btn_cmp, {
        text: _ctx.$root.static_text.buttons.cancel,
        background: false,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeBuildMode()))
      }, null, 8, ["text"]),
      _createVNode(_component_btn_cmp, {
        class: _normalizeClass({'_disabled': !_ctx.has_checked_maps}),
        text: 'Далее',
        onClick: _ctx.openChoosesMap
      }, null, 8, ["class", "onClick"])
    ], 2)
  ], 2))
}