
import Menubar from "../../components/ui-components/oil-menubar/menubar-cmp.vue"
import Rules from "../../components/ui-components/oil-rules/rules-cmp.vue"

interface ContextComponent {
    active_tab: number
}

export default {
    data() {
        return {
            active_tab: 1 as number,
        };
    },
    components: {
        "menubar-cmp": Menubar,
        "rules-cmp": Rules,
    },
    methods: {
        setActiveTab(this: ContextComponent,val: number) {            
            this.active_tab = val;
        },
    },
    mounted() {
        console.log('111');
        
    }
};
