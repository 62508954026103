
import { defineComponent, reactive, watch } from "vue"
import imgText from '../oil-img-text/img-text.vue'

export default defineComponent({
    data() {
        return {
            openMore: '',
            visible: false,
            // icons: [
            //     {
            //         icon: null
            //     },
            //     {
            //         icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6673 4.16663C16.6673 5.54734 13.6825 6.66663 10.0007 6.66663C6.31875 6.66663 3.33398 5.54734 3.33398 4.16663M16.6673 4.16663C16.6673 2.78591 13.6825 1.66663 10.0007 1.66663C6.31875 1.66663 3.33398 2.78591 3.33398 4.16663M16.6673 4.16663V15.8333C16.6673 17.214 13.6825 18.3333 10.0007 18.3333C6.31875 18.3333 3.33398 17.214 3.33398 15.8333V4.16663M16.6673 8.05546C16.6673 9.43617 13.6825 10.5555 10.0007 10.5555C6.31875 10.5555 3.33398 9.43617 3.33398 8.05546M16.6673 11.9416C16.6673 13.3223 13.6825 14.4416 10.0007 14.4416C6.31875 14.4416 3.33398 13.3223 3.33398 11.9416" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            //     },
            //     {
            //         icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 8.33329H2.5M13.3333 1.66663V4.99996M6.66667 1.66663V4.99996M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8211 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7334 17.5 14.3333V7.33329C17.5 5.93316 17.5 5.2331 17.2275 4.69832C16.9878 4.22791 16.6054 3.84546 16.135 3.60578C15.6002 3.33329 14.9001 3.33329 13.5 3.33329H6.5C5.09987 3.33329 4.3998 3.33329 3.86502 3.60578C3.39462 3.84546 3.01217 4.22791 2.77248 4.69832C2.5 5.2331 2.5 5.93316 2.5 7.33329V14.3333C2.5 15.7334 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8211 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            //     },
            //     {
            //         icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3327 17.5V15.8333C18.3327 14.2801 17.2704 12.9751 15.8327 12.605M12.916 2.7423C14.1376 3.23679 14.9993 4.43443 14.9993 5.83333C14.9993 7.23224 14.1376 8.42988 12.916 8.92437M14.166 17.5C14.166 15.9469 14.166 15.1703 13.9123 14.5577C13.574 13.741 12.9251 13.092 12.1083 12.7537C11.4957 12.5 10.7192 12.5 9.16602 12.5H6.66602C5.11288 12.5 4.33631 12.5 3.72374 12.7537C2.90698 13.092 2.25806 13.741 1.91975 14.5577C1.66602 15.1703 1.66602 15.9469 1.66602 17.5M11.2493 5.83333C11.2493 7.67428 9.75697 9.16667 7.91602 9.16667C6.07507 9.16667 4.58268 7.67428 4.58268 5.83333C4.58268 3.99238 6.07507 2.5 7.91602 2.5C9.75697 2.5 11.2493 3.99238 11.2493 5.83333Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            //     },
            //     {
            //         icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8331 1.66663L3.41094 10.5732C3.12026 10.922 2.97492 11.0964 2.9727 11.2437C2.97077 11.3717 3.02783 11.4935 3.12744 11.574C3.24201 11.6666 3.46904 11.6666 3.92308 11.6666H9.99972L9.16639 18.3333L16.5885 9.42675C16.8792 9.07794 17.0245 8.90354 17.0267 8.75624C17.0287 8.62819 16.9716 8.50637 16.872 8.42588C16.7574 8.33329 16.5304 8.33329 16.0764 8.33329H9.99972L10.8331 1.66663Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            //     }
            // ],
        }
    },
    props: {
        name: {
            type: String,
            default: 'Статьи расходов'
        },
        money: {
            type: String,
            default: 'Деньги'
        },
        time: {
            type: String,
            default: 'Время'
        },
        brigades: {
            type: String,
            default: 'Бригады'
        },
        energy: {
            type: String,
            default: 'Энергия'
        },
        icon: {
            type: Boolean,
            default: true
        },
        table: {
            type: Object,
            default: () => {
                return {
                    header: {
                    type: 'header',
                    content: []
                    },
                    content: {
                        type: 'content',
                        content: []
                    }
                }
            }
        }
    },
    setup(props) {
        const sub_row = reactive({
            value: null as number|null
        })

        const icons =  [
            {
                icon: null
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6673 4.16663C16.6673 5.54734 13.6825 6.66663 10.0007 6.66663C6.31875 6.66663 3.33398 5.54734 3.33398 4.16663M16.6673 4.16663C16.6673 2.78591 13.6825 1.66663 10.0007 1.66663C6.31875 1.66663 3.33398 2.78591 3.33398 4.16663M16.6673 4.16663V15.8333C16.6673 17.214 13.6825 18.3333 10.0007 18.3333C6.31875 18.3333 3.33398 17.214 3.33398 15.8333V4.16663M16.6673 8.05546C16.6673 9.43617 13.6825 10.5555 10.0007 10.5555C6.31875 10.5555 3.33398 9.43617 3.33398 8.05546M16.6673 11.9416C16.6673 13.3223 13.6825 14.4416 10.0007 14.4416C6.31875 14.4416 3.33398 13.3223 3.33398 11.9416" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 8.33329H2.5M13.3333 1.66663V4.99996M6.66667 1.66663V4.99996M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8211 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7334 17.5 14.3333V7.33329C17.5 5.93316 17.5 5.2331 17.2275 4.69832C16.9878 4.22791 16.6054 3.84546 16.135 3.60578C15.6002 3.33329 14.9001 3.33329 13.5 3.33329H6.5C5.09987 3.33329 4.3998 3.33329 3.86502 3.60578C3.39462 3.84546 3.01217 4.22791 2.77248 4.69832C2.5 5.2331 2.5 5.93316 2.5 7.33329V14.3333C2.5 15.7334 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8211 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3327 17.5V15.8333C18.3327 14.2801 17.2704 12.9751 15.8327 12.605M12.916 2.7423C14.1376 3.23679 14.9993 4.43443 14.9993 5.83333C14.9993 7.23224 14.1376 8.42988 12.916 8.92437M14.166 17.5C14.166 15.9469 14.166 15.1703 13.9123 14.5577C13.574 13.741 12.9251 13.092 12.1083 12.7537C11.4957 12.5 10.7192 12.5 9.16602 12.5H6.66602C5.11288 12.5 4.33631 12.5 3.72374 12.7537C2.90698 13.092 2.25806 13.741 1.91975 14.5577C1.66602 15.1703 1.66602 15.9469 1.66602 17.5M11.2493 5.83333C11.2493 7.67428 9.75697 9.16667 7.91602 9.16667C6.07507 9.16667 4.58268 7.67428 4.58268 5.83333C4.58268 3.99238 6.07507 2.5 7.91602 2.5C9.75697 2.5 11.2493 3.99238 11.2493 5.83333Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8331 1.66663L3.41094 10.5732C3.12026 10.922 2.97492 11.0964 2.9727 11.2437C2.97077 11.3717 3.02783 11.4935 3.12744 11.574C3.24201 11.6666 3.46904 11.6666 3.92308 11.6666H9.99972L9.16639 18.3333L16.5885 9.42675C16.8792 9.07794 17.0245 8.90354 17.0267 8.75624C17.0287 8.62819 16.9716 8.50637 16.872 8.42588C16.7574 8.33329 16.5304 8.33329 16.0764 8.33329H9.99972L10.8331 1.66663Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            }
        ]

        watch(() => props.table, () => {            
            if(props.table.header.content.length) {
                props.table.header.content.map((item, idx) => Object.assign(item, icons.find((img, index) => idx === index )))
            }
        })

        return {
            sub_row,
            icons
        }
    },
    methods: {
        openMenu(val) {
            if(this.openMore !== val) {
                this.openMore = val
            } else {
                this.openMore = ''
            }
            
        },
        openSubRow(idx) {
            if(this.sub_row.value === idx) {
                this.sub_row.value = null
            } else {
                this.sub_row.value = idx
            }
            
        },
        choose(val) {
            this.choose = val
        },
        sumOC(val) {            
            return val.reduce((accumulator, currentValue) => {return accumulator + Number(currentValue.oc)}, 0)
        },
        sumWfu(val) {
            return val.reduce((accumulator, currentValue) => {return accumulator + Number(currentValue.wfu)}, 0)
        },
        sumEu(val) {            
            return val.reduce((accumulator, currentValue) => {return accumulator + Number(currentValue.eu)}, 0)
        },
        sumTu(val) {            
            return Math.max.apply(null, val.map(item => item.tu))
        }
    },
    components: {
        'img-cmp': imgText
    },
    // created() {        
    //     this.$props.table.content.content.map((item, idx) => Object.assign(item, this.icons.find((img, index) => idx === index )))
    // }
    created() {
        // console.log(this.table.header);
    }
})
