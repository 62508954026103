<template>
    <div class="oil-switch" @click="toggle">
        <div class="oil-switch__toggle" :class="{'_active-tube': active === 'ByTube', '_active-oil-tube': active === 'ByOilTube'}">
            <div class="oil-switch__toggle-circle"></div>
        </div>
        <span v-if="title">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        activeToggle: {
            type: String,
            default: 'ByRoad'
        },
    },
    data() {
        return {
            active: this.activeToggle
        }
    },
    watch: {
        activeToggle(newVal) {
            this.active = newVal;
            console.log(newVal);
        }
    },
    methods: {
        toggle(e) {
            const clickX = e.offsetX;

            if (clickX < 20) {
                this.active = 'ByRoad';
            } else if (clickX < 40 && clickX > 20) {
                this.active = 'ByTube';
            } else {
                this.active = 'ByOilTube';
            }

            this.$emit('toggle', this.active);
        }
    },
    created() {
        this.active = this.activeToggle;
    }
}
</script>