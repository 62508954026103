
import { defineComponent } from 'vue'

interface Arragement {
    x: number,
    y: number,
    color: string
}

export default defineComponent({
    props: {
        arrangements: {
            type: Array as () => Arragement[], 
            default: () => []
        },
        data_type: {
            type: String,
            default: ''
        }
    },
    methods: {
        mappingArragement(x: number, y: number) {
            return this.$props.arrangements.find(arrangement => arrangement.x === x && arrangement.y === y)?.color
        },
    }
})
