<template>
    <div class="oil-img-text" >
        <slot></slot>
        <span class="oil-img-text__units">{{ text }}</span>
    </div>
</template>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'plug'
            }
        },
    }
</script>
