<template>
  <div :class="'oil-input'">
    <input
      :class="[
        'oil-input__value',
        { 'oil-input__value-fill': value && value.length },
      ]"
      @keyup="set"
      :type="type"
      v-model="value"
    />
    <label
      :class="[
        'oil-input__label',
        { 'oil-input__label-fill': value && value.length },
      ]"
      v-if="label.length"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "mainInput",
  props: {
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    initValue: {
      type: String,
      default: ''
    },
    objKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.$props.initValue,
    };
  },
  methods: {
    set() {
      this.$emit("set", { value: this.value, type: this.$props.type, key: this.$props.objKey });
    },
  },
};
</script>
