export default {
    namespaced: true,
    state: {
        curtain: true,
        data: []
    },
    getters: {

    },
    mutations: {
        enableCurtain(state) {
            state.curtain = true
        },
        disableCurtain(state) {
            state.curtain = false            
        },
    },
}