import { createStore } from 'vuex'
import request from './modules/request'
import modal from './modules/modal'
import curtain from './modules/curtain'
import notification from './modules/notification'
import building from './modules/building'
import polygon from './modules/polygon'

const store = createStore({
    state: {},
    mutations: {},
    modules: {
        request,
        modal,
        curtain,
        notification,
        building,
        polygon
    },
    actions: {}
})

export default store