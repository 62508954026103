
    //разобраться с цветами и китайским языком, пока все дисплей нан
    import { defineComponent } from 'vue';
    import inputCmp from '../../../ui-components/oil-input/input-cmp.vue'
    import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'

    export default defineComponent({
        props: {
            main_class: {
                type: String,
            }
        },
        data() {
            return {
                polygon_name: '',
                color_polygon: ''
            }
        },
        components: {
            'input-cmp': inputCmp,
            'button-cmp': buttonCmp,
        },
        methods: {
            setParamsPolygon() {
                console.log(this.color_polygon);
            }
        }
    })

