<template>
    <div :class="`${main_class}__arrangement`">
        <h2 :class="`${main_class}__arrangement__title`">{{ $root.static_text.modal.arrangement.title }}</h2>
        <div :class="`${main_class}__arrangement__coords`">
            <span>{{ $root.static_text.modal.arrangement.region }} x: {{ data.coords.region.x }} y: {{ data.coords.region.y }}</span>
            <span>{{ $root.static_text.modal.arrangement.cell }} x: {{ data.coords.cell.x }} y: {{ data.coords.cell.y }}</span>
        </div>
        <div :class="`${main_class}__arrangement__table`">
            <div :class="[`${main_class}__arrangement__table__row`, {_header: row.type === 'header'}]" v-for="row in data.table" :key="row">
                <template v-if="row.type === 'header'">
                    <span 
                        :class="`${main_class}__arrangement__table__row__information__elem`" 
                        v-for="column_name in row.content" 
                        :key="column_name.id"
                    >{{ column_name.text }}</span>                
                </template>
                <template v-else>
                    <div v-for="object in row.content" :key="object.id" :class="`${main_class}__arrangement__table__row__information`">
                        <div :class="`${main_class}__arrangement__table__row__information__elem`">
                            <div :class="[`${main_class}__arrangement__table__row__information__elem__radio`, {_active: arrangement_type.value === object.type}]" @click="setType(object.type)">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5" cy="5" r="5" fill="#176DC1"/>
                                </svg>
                            </div>
                            <span>{{ object.objectOfArrangement }}</span>
                        </div>
                        <span :class="`${main_class}__arrangement__table__row__information__elem`">{{ object.opex }}</span>
                        <span :class="`${main_class}__arrangement__table__row__information__elem`">{{ object.tu }}</span>
                        <span :class="`${main_class}__arrangement__table__row__information__elem`">{{ object.wfu }}</span>
                        <span :class="`${main_class}__arrangement__table__row__information__elem`">{{ object.eu }}</span>
                    </div>
                </template>
            </div>
        </div>
        <div :class="`${main_class}__arrangement__btns`">
            <button-cmp
                :text="$root.static_text.buttons.cancel" 
                :background="false"
                @click="closeModal"
            />
            <button-cmp
                :text="$root.static_text.buttons.add" 
                @click="setArrangement"
            />
        </div>
    </div>
</template>
<script>
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { reactive } from 'vue'


export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        main_class: {
            type: String,
        }
    },
    setup(props) {
        const store = useStore()

        const closeModal = () => {
            store.commit('modal/closeModal')
        }

        const arrangement_type = reactive({
            value: ''
        })

        const setType = (value_arrangement) => {
            arrangement_type.value = value_arrangement
        }

        const setArrangement = () => {
            const url = 'api/v1/Purchased/ObjectOfArrangement'

            axios
                .post(url, {
                    "Key": arrangement_type.value,
                    "Position": {
                        "X": props.data.coords.region.x,
                        "Y": props.data.coords.region.y,
                        "XInCell": props.data.coords.cell.x,
                        "YInCell": props.data.coords.cell.y
                    }
                })
                .then(server_response => {
                    console.log(server_response);
                })
                .catch(err => {
                    store.commit('notification/openNotification',
                        {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data[0]
                        }
                    )
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'arrangement')
                    closeModal()
                })
        }

        return {
            closeModal,
            setArrangement,
            setType,
            arrangement_type
        }
    },
    components: {
        'button-cmp': buttonCmp
    },
    }
</script>
