
import { defineComponent, reactive } from 'vue'
import axios from 'axios'
import { mapMutations, mapState } from 'vuex'
import btnCmp from '../../../ui-components/oil-button/button-cmp.vue'


export default defineComponent({
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    map_length: 0,
                    cost_type: 0,
                    values: 0
                }
            }
        },
        class: {
            type: String,
        }
    },
    setup() {
        const cost_table = reactive({
            response_table: {} as any,
            content: [] as any
        })

        const disable = reactive({
            value: true as boolean
        })

        return {
            cost_table,
            disable
        }
    },
    data() {
        return {
            map_length: this.$props.data.map_length - 1 <= 64,
            table: [
                {
                    name: this.$root.static_text.table.capex,
                    value: -80000
                },
                {
                    name: this.$root.static_text.table.tu,
                    value: -30
                },
            ] as  any
        }
    },
    components: {
        'btn-cmp': btnCmp
    },
    computed: {
        ...mapState('building', ['type_map'])
    },
    methods: {
        ...mapMutations('modal', ['closeModal']),
        ...mapMutations('building', ['setBuildMap']),
        ...mapMutations('request', ['updateMap']),
        ...mapMutations('building', ['updateBuildMap']),
        ...mapMutations('notification', ['openNotification']),

        saveBuildMap() {                        
            this.setBuildMap(false)
            axios
                .post('api/v1/Purchased/SurfaceImage', [
                    {

                        "surfaceImageId": this.type_map,
                        "cornerAX": this.$props.data.buying_map.initial_point.x,
                        "cornerAY": this.$props.data.buying_map.initial_point.y,
                        "cornerBX": this.$props.data.buying_map.end_point.x,
                        "cornerBY": this.$props.data.buying_map.end_point.y
                    }
                ])
                .then(() =>  {
                    this.openNotification({
                        props: {
                            success: true,
                            title: 'Успех!',
                            desc: 'Карта успешно добавлена!'
                        }
                    })})
                .finally(() => {
                    this.closeModal()
                    this.updateMap('buy_maps')
                })
                .catch(err => {
                    this.openNotification({
                        props: {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        }
                    })
                })
        },
        undoBuildMap() {
            this.updateBuildMap(true)
            this.closeModal()
        }
    },
    mounted() {
        axios
            .get(`Views/Browser/Map/Purchase/SurfaceImage/${this.type_map}`)
            .then(resp => this.cost_table.response_table = resp.data)
            .finally(() => {
                console.log(this.$props.data.map_length - 1);
                                
                this.table[0].value = this.cost_table.response_table.capex * (this.$props.data.map_length - 1)
                this.table[1].value = this.cost_table.response_table.tu
                this.disable.value = false
            })
    }
})
