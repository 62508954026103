 
import { defineComponent, onMounted, reactive, watch } from 'vue'
import Menubar from "../oil-menubar/menubar-cmp.vue"
import Map from "../oil-map/map-refactor.vue"
import btnCmp from '../oil-button/button-cmp.vue'
import inputCmp from "../oil-input/input-cmp.vue"
import checkboxCmp from "../oil-checkbox/checkbox-cmp.vue"
import axios from 'axios'

import { mapState, mapMutations } from 'vuex'

export default defineComponent({
    setup() {
        const tabs = reactive({}) as Array<{
            id: number,
            text: string,
            type: string
        }>     

        const active_map = reactive([
            {
                id: 'contourMap',
                active: false
            },
            {
                id: 'satellitePhoto',
                active: false
            },
            {
                id: 'thicknessMap',
                active: false
            },
        ])

        const build = reactive({
            communication: false,
            polygon: false,
            map: false
        })

        const preloader = reactive({
            value: true as Boolean
        })

        const map_setting_opener = reactive({
            value: false as boolean
        })

        const active_tab = reactive({
            id: 1 as number,
            type: 'arrangement' as string
        })

        const open_filter = reactive({
            value: false as boolean
        })

        const picture_state = reactive({
            value: '' as string
        })     

        const polygon_error = reactive({
            value: '' as string
        })

        // const maps = reactive({
        //     view: [
        //         {
        //             id: 'contourMap',
        //             active: false
        //         },
        //         {
        //             id: 'satellitePhoto',
        //             active: false
        //         },
        //         {
        //             id: 'thicknessMap',
        //             active: false
        //         },
        //     ] as any
        // })
        
        const map_setting = reactive({
            maps: [] as Array<{
                id: string,
                description: string,
                isPurchased: boolean,
                // imageLink: string,
                // status: string,
                // type: string,
                imageData: string,
            }>
        })

        const updateMapSetting = () => {
            axios
                .get('Views/Browser/Map/Purchase/SurfaceImage')
                .then(response_map_filter => {
                    map_setting.maps = response_map_filter.data                    
                })
        }

        const setCheckbox = (val) => {
            if(!active_map.find(map_type => map_type.id === val.checkbox_id)!.active) {
                active_map.find(map_type => map_type.id === val.checkbox_id)!.active = true 
            } else {
                active_map.find(map_type => map_type.id === val.checkbox_id)!.active = false 
            }
            
        }

        const communicationState = (val) => {
            build.communication = val
            active_tab.type = 'arrangement'
        }

        watch(() => build.map, () => {
            build.map === false ? updateMapSetting() : ''
        })

        onMounted(() => {
            updateMapSetting();
        })

        return {
            tabs,
            preloader,
            active_tab,
            build,
            picture_state,
            polygon_error,
            open_filter,
            map_setting,
            map_setting_opener,
            setCheckbox,
            active_map,
            communicationState
        }
    },
    methods: {
        ...mapMutations('modal', ['openModal']),
        ...mapMutations('polygon', ['reloadData']),
        ...mapMutations('building', ['visibleMapState']),
        ...mapMutations('notification', ['openNotification']),
        ...mapMutations('request', ['updateMap', 'setReloadState']),

        setPolygonName(val) {
            this.polygon_name = val.value
        },
        chooseMenu(tab) {
            this.active_tab.id = tab.id
            this.active_tab.type = tab.type
            this.$emit('selected_tab', tab.type)
            if(tab.type === 'communication') {
                this.buildingState(true)
            } else if(tab.type === 'maps') {
                this.openModal({
                    newState: 'chooseMapModal',
                    props: {
                        data: {},
                    }
                })
            } else if(tab.type === '3d_model') {
                this.openModal({
                    newState: 'threeDModal',
                    props: {
                        data: {},
                    }
                })
            }
        },
        stateVisibleMap(val) {                                    
            this.visibleMapState(val)
        },
        closePolygonTooltip() {
            this.chooseMenu({type:'ObjectOfArrangement', id: 1})
        },
        buildingState(val) {
            if(this.build.communication) {
                this.chooseMenu({type:'ObjectOfArrangement', id: 1})
            }
            this.build.communication = val
        },
        buyMapState(val) {
            if(this.build.map) {
                this.chooseMenu({type:'ObjectOfArrangement', id: 1})
                
            }
            this.build.map = val
        },
        buildingPolygon(val) {
            if(this.build.polygon) {
                this.chooseMenu({type:'ObjectOfArrangement', id: 1})
            }
            this.build.polygon = val
        },
        changeColor() {
            let r = Math.round(Math.random() * 255);
            let g = Math.round(Math.random() * 255);
            let b = Math.round(Math.random() * 255);
            let color = "rgb(" + r + "," + g + "," + b +")";

            this.option_signature = {
                penColor: color,
            };
            
        },
        undoPolygonPicture() {
            (this.$refs.signaturePad as any).undoSignature();
        },
        saveImage() {
            if(this.polygon_name.length) {
                localStorage.removeItem('images')
                const signaturePad = (this.$refs.signaturePad as any).signaturePad
                const dataUrl = signaturePad.toDataURL()
                this.images.push({polygon_name: this.polygon_name, images: dataUrl, color: this.option_signature.penColor})
                localStorage.setItem('images', JSON.stringify(this.images))
                
                this.polygon_error.value = ''

                this.openNotification({
                    success: true,
                    title: 'Успех!',
                    desc: 'Полигон успешно добавлен!'
                })
                this.reloadData(true)
            } else {
                this.polygon_error.value = 'Поле обязательное для заполнения'
            }
        },
        openMapFilter() {
            this.open_filter.value = !this.open_filter.value
        },

        openMapSettings() {
            this.map_setting_opener.value = !this.map_setting_opener.value
        }
    },
    mounted() {

        setTimeout(() => {
            const images_cache = JSON.parse(localStorage.getItem('images') as any)
            
            if(images_cache) {
                this.images = images_cache
            }

        }, 1000)
    },
    created() {
        const menu_url = 'api/v1/static/text?key=mainMap'
        // добавить на бэкенд 

        axios
            .get(menu_url)
            .then(resp => {
                this.tabs = resp.data.header
                this.tabs = this.tabs.filter(tabs => tabs.type !== 'verticalSeismic' && tabs.type !== 'horizontalSeismic')
                
                this.tabs.push({id: 8, text: "Сейсмика", type: "seismic"})
                // this.tabs.push({id: 6, text: "Полигоны", type: "polygon"})
                this.tabs.push({id: 7, text: "Карты", type: "maps"})
                this.tabs.push({id: 9, text: "3D-модель", type: "3d_model"})
            })
            .finally(() => {
                this.preloader.value = false
            })
    },
    data() {
        return {
            menu: [],
            // active: 1,
            visible_block: true,
            setting: false,
            type: 'arrangement',
            maps: [],
            building: false,
            images: [] as any,
            polygon_name: '',
            color: "#ff0000",
            option_signature: {
                penColor: '#c0f'
            },
        }
    },
    watch: {
        polygon_reloaded() {
            if(this.polygon_reloaded) {                
                const images_cache = JSON.parse(localStorage.getItem('images') as any)
                if(images_cache) {
                    
                    this.images = images_cache
                    this.setReloadState('Polygon')
                }

                // this.reloadData(false)
            }
        },
        // update() {
        //     if(this.update === 'buy_maps') {
        //         this.buyMapState(false)
        //     }
        //     if(this.update === 'buy_maps') {
        //         axios
        //             .get('/api/v1/Purchased/SurfaceImage')
        //             .then(response_map_filter => {
        //                 this.map_setting.maps = response_map_filter.data
        //                 // this.map_setting.maps.unshift({
        //                 //     id: 'removeTheCard',
        //                 //     name: 'Убрать карту',
        //                 //     imageLink: "",
        //                 //     status: "",
        //                 //     type: "",
        //                 //     imageData: ""
        //                 // })
        //             }
        //         )
        //     }
        // },
        build_map() {
            this.buyMapState(false)
        }
    },
    computed: {
        ...mapState('request', ['host']),
        ...mapState('polygon', ['polygon_reloaded']),
        ...mapState('building', ['build_map']),
        ...mapState('building', ['visibleMap']),
        // ...mapState('request', ['update']),
    },
    components: {
        'menubar-cmp': Menubar,
        'map-cmp': Map,
        'btn-cmp': btnCmp,
        'input-cmp': inputCmp,
        'checkbox-cmp': checkboxCmp
    },
})
