import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "oil-drilling-page oil-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "oil-drilling-page__header" }
const _hoisted_4 = { class: "oil-drilling-page__header-menubar" }
const _hoisted_5 = { class: "oil-drilling-page__content" }
const _hoisted_6 = { class: "oil-drilling-page__content-container" }
const _hoisted_7 = { class: "oil-drilling-page__content-container-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_menubar_cmp = _resolveComponent("menubar-cmp")!
  const _component_profile_table = _resolveComponent("profile-table")!
  const _component_construct_table = _resolveComponent("construct-table")!
  const _component_research_table = _resolveComponent("research-table")!
  const _component_gtm_table = _resolveComponent("gtm-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_select_input, {
              class: "oil-drilling-page__header-select",
              list: _ctx.borehole_list.list,
              onBoreholeId: _ctx.boreholeSelection,
              select_values: _ctx.borehole_list.list[0].name
            }, null, 8, ["list", "onBoreholeId", "select_values"]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs_menu.menubar_list, (tab) => {
                return (_openBlock(), _createBlock(_component_menubar_cmp, {
                  key: tab.id,
                  active: _ctx.tabs_menu.active_tab,
                  id: tab.id,
                  class: "oil-drilling-page__header-menubar-item",
                  text: tab.text,
                  onClick: ($event: any) => (_ctx.selectMenu(tab.id))
                }, null, 8, ["active", "id", "text", "onClick"]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_TransitionGroup, { name: "fadeRight" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", null, [
                      _createVNode(_component_profile_table, {
                        table: _ctx.boreholes_data.boreholes_profile_data,
                        onReload_data: _ctx.updateTable,
                        boreholeId: _ctx.selected_borehole.value
                      }, null, 8, ["table", "onReload_data", "boreholeId"])
                    ], 512), [
                      [_vShow, _ctx.tabs_menu.active_tab === 'Profile']
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createVNode(_component_construct_table, {
                        table: _ctx.boreholes_data.boreholes_construction_data,
                        onReload_data: _ctx.updateTable,
                        boreholeId: _ctx.selected_borehole.value,
                        change_state: _ctx.boreholes_data.borehole_change
                      }, null, 8, ["table", "onReload_data", "boreholeId", "change_state"])
                    ], 512), [
                      [_vShow, _ctx.tabs_menu.active_tab === 'Construction']
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createVNode(_component_research_table, {
                        table: _ctx.boreholes_data.boreholes_research_data,
                        onReload_data: _ctx.updateTable,
                        boreholeId: _ctx.selected_borehole.value,
                        onResearch_type: _ctx.setResearchType,
                        boreholeType: _ctx.borehole_type.value
                      }, null, 8, ["table", "onReload_data", "boreholeId", "onResearch_type", "boreholeType"])
                    ], 512), [
                      [_vShow, _ctx.tabs_menu.active_tab === 'Research']
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createVNode(_component_gtm_table, {
                        table: _ctx.boreholes_data.boreholes_intervention_data,
                        onReload_data: _ctx.updateTable,
                        id: _ctx.selected_borehole.value,
                        price_table: _ctx.boreholes_data.boreholes_intervention_prices_data
                      }, null, 8, ["table", "onReload_data", "id", "price_table"])
                    ], 512), [
                      [_vShow, _ctx.tabs_menu.active_tab === 'GTM']
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}