
import Menubar from '../../components/ui-components/oil-menubar/menubar-cmp.vue'
import Wells from '../../components/ui-components/oil-wells/wells-cmp.vue'
import axios from 'axios'
import { Chart } from 'highcharts-vue'
import { mapState } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            active: 1,
            result: [] as any,
            options: [],
            menu: [
                {
                    name: "Скважины",
                    id: 1
                },
                {
                    name: "Сейсмика",
                    id: 2
                },
                // {
                //     name: "Границы горизонтов",
                //     id: 3
                // },
            ],
            update: false,
            visible: false,
            chartOptions: [] as any,
            images: [] as any
        }
    },
    components: {
        'menubar-cmp': Menubar,
        'wells-cmp': Wells,
        highcharts: Chart,
    },
    computed: {
        ...mapState('request', ['host'])
    },
    watch: {
        result() {
            if(this.result.content.content.length) {
                this.visible = true
            }
        },
    },
    methods: {
        selectTabs(val) {
            this.active = val
        },
        changeData(val) {
            this.update = val
        }
    },
    created() {
        const urls = [
            `Views/browser/result/borehole`,
            `api/v1/Purchased/seismic`
        ]
        axios.all(urls.map((endpoint) => axios.get(endpoint)))
            .then(
                axios.spread((firstResp, secondResp) => {
                    this.result = firstResp.data 
                    this.chartOptions = secondResp.data
                    this.images = secondResp.data.map((item) => item.imageLink)
                })
            )
            .finally(() => {                
                if(this.result.length) {
                    this.chartOptions[0].yAxis = {
                        title: {
                            text: ''
                        }
                    }
                }
            })
    }
})
