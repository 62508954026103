
import radioCmp from '../../../ui-components/oil-radio/radio-cmp.vue'
import { useStore } from 'vuex'
import axios from 'axios'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'


export default defineComponent({
    props: {
        data: {
            type: Object as () => ({ well_pad: string, order_id: number }),
            default: () => ({ well_pad: '', order_id: 0 })
        },
        main_class: {
            type: String,
        }
    },
    setup(props) {
        const store = useStore()
        const router = useRouter()

        const borehole = reactive({
            type_boreholes :[
                {
                    id: 1,
                    name: 'Разведывательная',
                    type: 'Research'
                },
                {
                    id: 2,
                    name: 'Эксплуатационная',
                    type: 'Operational'
                }
            ],
            active_type: 1
        })

        const setMapType = (active_type: number) => {
            borehole.active_type = active_type
        }

        const addBoreholes = () => {
            const url = 'api/v1/Purchased/Borehole'
            
            axios
                .post(url, {
                    WellPadId: props.data.well_pad,
                    BoreholeType: borehole.active_type,
                    OrderId: props.data.order_id
                })
                .then(resp => {
                    console.log(resp);
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'borehole')
                    router.push('/drilling')
                    closeModal()
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteWellPad = () => {
            axios
                .delete(`api/v1/Purchased/WellPad/${props.data.well_pad}`)
                .then(resp => {
                    console.log(resp.data);
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'borehole')
                    closeModal()
                })
                .catch(err => {
                    store.commit('notification/openNotification',{
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const closeModal = () => {
            store.commit('modal/closeModal')
        }

        return {
            borehole,
            setMapType,
            addBoreholes,
            deleteWellPad,
            closeModal
        }
    },
    // data() {
    //     return {
    //         type_boreholes: [
    //             {
    //                 id: 1,
    //                 name: this.$root.static_text.modal.borehole.research,
    //                 type: 'Research'
    //             },
    //             {
    //                 id: 2,
    //                 name: 'Эксплуатационная',
    //                 type: 'Operational'
    //             }
    //         ],
    //         type: 1,
    //     }
    // },
    components: {
        'button-cmp': buttonCmp,
        'radio-cmp': radioCmp
    },
    // methods: {
    //     ...mapMutations('modal', ['closeModal']),
    //     ...mapMutations('notification', ['openNotification']),
    //     ...mapMutations('request', ['updateMap']),
    //     ...mapMutations('notification', ['openNotification']),
    //     ...mapActions('request', ['setData']),


    //     setValue(val) {
    //         for (let i = 0; i < this.form.length; i++) {
    //             if (this.form[i].type === val.type) {
    //                 this.form[i].value = val.value;
    //             }
    //         }
    //     },
        // deleteWellPad() {
        //     axios
        //         .delete(`api/v1/Purchased/WellPad/${this.$props.data.well_pad}`)
        //         .then(resp => {
        //             console.log(resp.data);
        //         })
        //         .finally(() => {
        //             store.commit('request/setReloadState', 'borehole')
        //             this.closeModal()
        //         })
        // },
    //     setMapType(val) {
    //         this.type = val
    //     },
    //     addBoreholes() {
    //         const url = 'api/v1/Purchased/Borehole'
            
    //         axios
    //             .post(url, {
    //                 WellPadId: this.$props.data.well_pad,
    //                 BoreholeType: this.type,
    //                 OrderId: this.$props.data.order_id
    //             })
    //             .then(resp => {
    //                 console.log(resp);
    //                 this.openNotification({
    //                     props: {
    //                         success: true,
    //                         title: 'Успех!',
    //                         desc: 'Скважина успешно добавлена!'
    //                     }
    //                 })
    //             })
    //             .finally(() => {
    //                 store.commit('request/setReloadState', 'borehole')
    //                 this.closeModal()
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //                 this.openNotification({
    //                     props: {
    //                         error: true,
    //                         title: 'Ошибка!',
    //                         desc: err.response.data
    //                     }
    //                 })
    //             })
    //     },
    //     close() {
    //         this.closeModal()
    //     }
    // }
})
