
import InputCmp from "../../oil-input/input-cmp.vue"
import axios from 'axios'
import { useStore } from "vuex"
import { watch, defineComponent, reactive } from 'vue'

    export default defineComponent({
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Object,
                default:() => ({
                    header: [],
                    content: []
                })
            },
            radio: {
                type: Boolean,
                default: false
            },
            boreholeId: {
                type: Number,
                default: 0
            }
        },
        setup(props, { emit }) {
            const store = useStore()

            const table_data = reactive({
                general_table_data: [] as Array<{
                    number: number | string,
                    id: number | string,
                    text: string
                }>
            })

            const editing_row = reactive({
                row_id: 0
            })

            watch(() => props.table, () => {
                table_data.general_table_data = props.table.header?.content.concat(props.table.content?.content)
                table_data.general_table_data[0].number = "№"
            })

            const editRow = (row_idx: number) => {
                editing_row.row_id = row_idx
            }

            const addCoords = () => {
                const url = `api/v1/Drilling/BoreholeTrajectory?boreholeId=${props.boreholeId}`

                axios 
                    .post(url)
                    .then((response_coords) => {
                        console.log(response_coords);
                        
                    })
                    .finally(() => {
                        emit('reload_data', {reload: true, table_type: 'profile'})
                    })
                    .catch(err => {
                        store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    })
            }

            const saveChanges = (row_id: number, row_tvd: string, row_x: string, row_y: string) => {
                const url = 'api/v1/Drilling/BoreholeTrajectory'

                axios
                    .patch(url, {
                        id: row_id,
                        x: row_x,
                        y: row_y,
                        z: row_tvd
                    })
                    .finally(() => {
                        emit('reload_data', {reload: true, table_type: 'profile'})
                        editRow(0)
                    })
                    .catch(err => {
                        store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    })
            }

            const deleteRow = (row_id: number) => {
                axios 
                    .delete(`/api/v1/Drilling/BoreholeTrajectory/${row_id}`)
                    .finally(() => {
                        emit('reload_data', {reload: true, table_type: 'profile'})
                    })
                    .catch(err => {
                        store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    })
            }

            const setValue = (value) => {                
                for (let i = 0; i < table_data.general_table_data.length; i++) {                    
                    if (table_data.general_table_data[i].id === value.type) {                        
                        table_data.general_table_data[i][value.key].text = value.value;
                    }
                }
            }

            return {
                table_data,
                editing_row,
                saveChanges,
                editRow,
                addCoords,
                deleteRow,
                setValue
            }
        },
        components: {
            'input-cmp': InputCmp
        },
    })
