 
import Menubar from "../oil-menubar/menubar-cmp.vue"
import tableCmp from "../oil-table/oil-table.vue"
import { defineComponent, reactive, onMounted, computed, watch } from "vue"
import { useStore } from 'vuex'
import axios from "axios"

/* eslint-disable */
    export default defineComponent({
        props: {
            reload_data_state: {
                type: String,
                default: ''
            }
        },
        setup(props) {
            const store = useStore()

            const tables_endpoints = [
                `api/v1/Static/Text?key=mapObjectsAndResearchesHeader&languageId=ru`,
                `Views/Browser/map/ObjectsAndResearches/borehole`,
                `Views/Browser/map/ObjectsAndResearches/objectOfArrangement`,
                `Views/Browser/map/ObjectsAndResearches/surfaceImage`,
                `Views/Browser/map/ObjectsAndResearches/seismic`,
                `Views/Browser/Map/ObjectsAndResearches/ServiceLine`,
                'Views/Browser/map/ObjectsAndResearches/surfaceImage',
                'Views/Browser/Map/ObjectsAndResearches/ModelProperty',
                'Views/Browser/Map/ObjectsAndResearches/LogName'
            ]

            const table_data = reactive({
                borehole: [] as Array<{
                    boreholes: string,
                    capex: string,
                    coords: {
                        cell: {
                            x: number,
                            y: number
                        },
                        region: {
                            x: number,
                            y: number
                        }
                    },
                    currentOpex: string,
                    eu: string,
                    id: number,
                    status: string,
                    statusColor: string,
                    tu: string,
                    typeOfDelivery: boolean,
                    wfu: string
                }>,
                object_of_arrangement: [] as Array<{
                    arragement: string,
                    capex: string,
                    coords: {
                        region: {
                            x: number,
                            y: number
                        }, 
                        cell: {
                            x:number,
                            y: number
                        }
                    },
                    eu: string,
                    id: number,
                    isDeleteAvailable: boolean,
                    opex: string,
                    status: string,
                    statusColor: string,
                    tu: string,
                    wfu: string
                }>,
                surface_image: [] as Array<{}>,
                seismic: [] as Array<{
                    capex: number,
                    id: number,
                    profile: string,
                    status: string,
                    statusColor: string,
                    tu: number,
                    wfu: number
                }>,
                communication: [] as Array<{
                    capex: string,
                    count: string,
                    eu: string,
                    id: number,
                    opex: string,
                    serviceLine: string,
                    status: string,
                    statusColor: string,
                    tu: string,
                    wfu: string
                }>,
                polygon: [] as Array<{
                    color: string,
                    images: string,
                    polygon_name: string
                }>,
                buy_maps: [] as Array<{
                    status: string,
                    statusColor: string,
                    tu: string,
                    capex: string,
                }>,
                model3D: [] as Array<{
                    status: string,
                    statusColor: string,
                    tu: string,
                    capex: string,
                    wfu: string
                }>,
                research: [] as Array<{
                    id: number,
                    isRemovable: boolean,
                    research: string,
                    borehole: string,
                    status: string,
                    statusColor: string,
                    capex: number | null,
                    opex: number | null,
                    tu: number | null,
                    wfu: number | null,
                    eu: number | null
                }>
            })

            const active_menubar = reactive({
                value: '' as string
            })

            const reload_data = computed(() => {
                return store.getters['modal/modalComponent']
            })

            const header = reactive({
                value: [] as Array<{
                    id: string,
                    text: string
                }>
            })

            const preloader = reactive({
                value: true as Boolean
            })

            const selectMenu = (selected_menu) => {
                active_menubar.value = selected_menu
            }

            watch(() => store.getters['request/reload'], (new_state) => {
                switch (new_state) {
                    case 'borehole': 
                        axios
                            .get(tables_endpoints[1])
                            .then(response_borehole => {
                                table_data.borehole = response_borehole.data.table.content                                
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', '')
                            })
                        break;
                    case 'arrangement': 
                        axios
                            .get(tables_endpoints[2])
                            .then(arrangement_response => {
                                table_data.object_of_arrangement = arrangement_response.data.content.content                                
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', '')
                            })
                        break;
                    case 'communication': 
                        axios
                            .get(tables_endpoints[5])
                            .then(communication_response => {
                                table_data.communication = communication_response.data.content.content                                
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', '')
                            })
                        break;
                    case 'seismic':
                        axios
                            .get(tables_endpoints[4])
                            .then(seismic_response => {
                                table_data.seismic = seismic_response.data.content.content      
                            })
                        break; 
                    case 'maps': 
                    
                        axios
                            .get(tables_endpoints[6])
                            .then(maps_response => {
                                table_data.buy_maps = maps_response.data.content.content                                
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', '')
                            })
                        break;
                    case 'Model3D': 
                        axios
                            .get(tables_endpoints[7])
                            .then(model3D_response => {
                                table_data.model3D = model3D_response.data.content.content
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', '')
                            })
                    case 'Research': 
                        axios
                            .get(tables_endpoints[8])
                            .then(research_response => {
                                table_data.research = research_response.data.content.content
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', '')
                            })
                            
                        break;
                    case 'Polygon':  
                        
                                                                      
                        table_data.polygon = JSON.parse(localStorage.getItem('images') as any) 

                        store.commit('request/setReloadState', '')
                        store.commit('polygon/reloadData', false)
                        break;
                    default:
                        break;
                }
            })

            // watch(reload_data, () => {
            //     switch(props.reload_data_state) {
            //         case 'borehole': 
            //             axios
            //                 .get(tables_endpoints[1])
            //                 .then(arrangement_response => {
            //                     table_data.object_of_arrangement = arrangement_response.data.table.content                                
            //                 })
            //             break;
            //         case 'arrangement': 
            //             axios
            //                 .get(tables_endpoints[2])
            //                 .then(response_borehole => {
            //                     table_data.borehole = response_borehole.data.table.content                                
            //                 })
            //             break;
            //         case 'communication': 
            //             axios
            //                 .get(tables_endpoints[5])
            //                 .then(communication_response => {
            //                     table_data.communication = communication_response.data.content.content                                
            //                 })
            //             break;
            //         case 'seismic': 
            //             axios
            //                 .get('Views/Browser/map/ObjectsAndResearches/seismic')
            //                 .then(response_seismic => {
            //                     table_data.seismic = response_seismic.data.content.content
            //                 })
            //             break;
                    
            //         case 'maps':                         
            //             axios
            //                 .get(tables_endpoints[3])
            //                 .then(response_maps => {
            //                     table_data.buy_maps = response_maps.data
            //                 })
            //                 .finally(() => {
            //                 })
            //             break;
                    
            //         default: 
            //             break;
            //     }
            // })

            onMounted(() => {
                axios.all(tables_endpoints.map((endpoint) => axios.get(endpoint)))
                    .then(
                        axios.spread((header_response, borehole_response, arrangement_response, image_response, seismic_response, communication_response, building_map_response, model3D_response, research_response) => {
                            header.value = header_response.data.header
                            table_data.borehole = borehole_response.data.table.content // поменять входные данные
                            table_data.object_of_arrangement = arrangement_response.data.content.content
                            table_data.surface_image = image_response.data.content
                            table_data.seismic = seismic_response.data.content.content
                            table_data.communication = communication_response.data.content.content
                            table_data.buy_maps = building_map_response.data.content.content
                            table_data.model3D = model3D_response.data.content.content
                            table_data.research = research_response.data.content.content
                            if(JSON.parse(localStorage.getItem('images') as any)) {
                                table_data.polygon = JSON.parse(localStorage.getItem('images') as any)
                            }

                            header.value = header.value.filter(tabs => tabs.id !== 'SurfaceImage')

                            header.value.find(tab => tab.id === 'Seismic')!.text = 'Сейсмика'
                            header.value.push({id: "Polygon", text: "Полигоны"})                            
                        })
                    )
                    .finally(() => {
                        active_menubar.value = table_data.borehole.length ? 'Borehole' : (table_data.seismic.length ? 'Seismic': (table_data.polygon.length ? 'Polygon' : (table_data.buy_maps.length ? 'BuyMaps' : 'ObjectOfArrangement'))) 
                    })
            })

            return {
                table_data,
                header,
                preloader,
                active_menubar,
                selectMenu
            }
        },
        // data() {
        //     return {
        //         data: [],
        //         visible: true,
        //         curtain: true,
        //         endpoints: [
        //             `api/v1/Static/Text?key=mapObjectsAndResearchesHeader&languageId=ru`,
        //             `Views/Browser/map/ObjectsAndResearches/borehole`,
        //             `Views/Browser/map/ObjectsAndResearches/objectOfArrangement`,
        //             `Views/Browser/map/ObjectsAndResearches/surfaceImage`,
        //             `Views/Browser/map/ObjectsAndResearches/seismic`,
        //             `Views/Browser/Map/ObjectsAndResearches/ServiceLine`,
        //             'Views/Browser/map/ObjectsAndResearches/surfaceImage'
        //         ]
        //     }
        // },
        components: {
            'menubar-cmp': Menubar,
            "table-cmp": tableCmp
        },
        // computed: {
        //     ...mapGetters('request', ['update']),
        //     ...mapState('request', ['update', 'host']),
        //     ...mapState('polygon', ['polygon_reloaded']),
        // },
        // watch: {
        //     polygon_reloaded() {
        //         if(this.polygon_reloaded) {                    
        //             this.table_data.polygon = JSON.parse(localStorage.getItem('images') as any)

        //             this.reloadData(false)
        //         }
        //     },
        //     update() {  
        //         // if(!this.table_data.borehole.length || !this.table_data.seismic.length || !this.table_data.polygon.length) {
        //         //     this.selectMenu('BuyMaps')                    
        //         // }

        //         switch(this.update) {
        //             case 'borehole': 
        //                 axios
        //                     .get('Views/Browser/map/ObjectsAndResearches/borehole')
        //                     .then(response_borehole => {
                                
        //                         this.table_data.borehole = response_borehole.data.table.content                                
        //                     })
        //                     .finally(() => {
        //                         // if(!this.table_data.borehole.length) {
        //                         //     this.selectMenu('BuyMaps')
        //                         // } else {
        //                         //     this.selectMenu('Borehole')
        //                         // }
        //                         this.updateMap('')
        //                     })
        //                 break;
        //             case 'seismic': 
        //                 axios
        //                     .get('Views/Browser/map/ObjectsAndResearches/seismic')
        //                     .then(response_seismic => {
        //                         this.table_data.seismic = response_seismic.data.content.content
        //                     })
        //                     .finally(() => {
        //                         // if(!this.table_data.borehole.length) {
        //                         //     this.selectMenu('BuyMaps')
        //                         // } else {
        //                         //     this.selectMenu('Seismic')
        //                         // }
        //                         this.updateMap('')
        //                     })  
        //                 break;
                    
        //             case 'buy_maps':                         
        //                 axios
        //                     .get('Views/Browser/map/ObjectsAndResearches/surfaceImage')
        //                     .then(response_maps => {
        //                         this.table_data.buy_maps = response_maps.data
        //                     })
        //                     .finally(() => {
        //                         // if(!this.table_data.borehole.length) {
        //                         //     this.selectMenu('BuyMaps')
        //                         // } else {
        //                         //     this.selectMenu('Polygon')
        //                         // }
        //                         this.updateMap('')
        //                     })
        //                 break;
                    
        //             default: 
        //                 break;
        //         }
        //     }
        // },
        // methods: {
            // ...mapMutations('polygon', ['reloadData']),
            // ...mapMutations('request', ['updateMap']),


            // selectMenu(val) {
                
            //     this.active_menubar.value = val;
            // },
            // visibleBlock(val) {
            //     this.visible = val; 
            // },
            // disableTab(tab) {
            //     if(tab.id === 'ObjectOfArrangement' && !this.table_data.object_of_arrangement.length ) {                                        
            //         return true
            //     } 
            //     else if(tab.id === 'Borehole' && !this.table_data.borehole.length ) {
            //         return true
            //     } 
            //     else if(tab.id === 'ServiceLine' && !this.table_data.communication.length ) {
            //         return true
            //     } 
            //     else if(tab.id === 'SurfaceImage' && !this.table_data.surface_image.length ) {
            //         return true
            //     } 
            //     else if(tab.id === 'Seismic' && !this.table_data.seismic.length ) {
            //         return true
            //     } 
            //     else if(tab.id === 'Polygon' && !this.table_data.polygon.length ) {
            //         return true
            //     } 
            //     else if(tab.id === 'BuyMaps' && !this.table_data.buy_maps.length ) {
            //         return true
            //     } 
            // },
        //     updateData() {
        //         switch(this.update) {
        //             case 'borehole': 
        //                 axios
        //                     .get('Views/Browser/map/ObjectsAndResearches/borehole')
        //                     .then(response_borehole => {
        //                         this.table_data.borehole = response_borehole.data.table.content
        //                     })
        //                     .finally(() => {
        //                         this.updateMap('')
        //                     })
        //                 break;
        //             case 'seismic': 
        //                 axios
        //                     .get('Views/Browser/map/ObjectsAndResearches/seismic')
        //                     .then(response_seismic => {
        //                         this.table_data.seismic = response_seismic.data.content.content
        //                     })
        //                     .finally(() => {
        //                         this.updateMap('')
        //                     })  
        //                 break;
                    
        //             case 'buy_maps': 
        //                     axios
        //                         .get('Views/Browser/map/ObjectsAndResearches/surfaceImage')
        //                         .then(response_maps => {
        //                             this.table_data.buy_maps = response_maps.data
        //                         })
        //                         .finally(() => {
        //                             this.updateMap('')
        //                         })
        //                 break;
                    
        //             default: 
        //                 break;
        //         }
        //     }
        // },
        // created() {
        //     axios.all(this.endpoints.map((endpoint) => axios.get(endpoint)))
        //         .then(
        //             axios.spread((header_response, borehole_response, arrangement_response, image_response, seismic_response, communication_response, building_map_response) => {
        //                 this.header.value = header_response.data.header
        //                 this.table_data.borehole = borehole_response.data.table.content // поменять входные данные
        //                 this.table_data.object_of_arrangement = arrangement_response.data.content.content
        //                 this.table_data.surface_image = image_response.data.content
        //                 this.table_data.seismic = seismic_response.data.content.content
        //                 this.table_data.communication = communication_response.data.content.content
        //                 this.table_data.buy_maps = building_map_response.data
        //                 if(JSON.parse(localStorage.getItem('images') as any)) {
        //                     this.table_data.polygon = JSON.parse(localStorage.getItem('images') as any)
        //                 }

        //                 this.header.value = this.header.value.filter(tabs => tabs.id !== 'SurfaceImage')

        //                 this.header.value.find(tab => tab.id === 'Seismic')!.text = '2D сейсмики'                            
        //                 this.header.value.push({id: "Polygon", text: "Полигоны"})
        //                 this.header.value.push({id: "BuyMaps", text: "Карты"})
        //             })
        //     )
        //     .finally(() => {
        //         this.preloader.value = false
        //         this.active_menubar.value = this.table_data.borehole.length ? 'ObjectOfArrangement' : (this.table_data.seismic.length ? 'Seismic': (this.table_data.polygon.length ? 'Polygon' : (this.table_data.buy_maps.length ? 'BuyMaps' : 'Borehole'))) 
        //     })
        // }
    })
