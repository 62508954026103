
import checkboxCmp from '../../oil-checkbox/checkbox-cmp.vue'
import menubarCmp from '../../oil-menubar/menubar-cmp.vue'
import btnCmp from '../../oil-button/button-cmp.vue'
import { defineComponent, reactive, watch } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'

    export default defineComponent({
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Object,
                default:() => ({
                    header: [],
                    content: []
                })
            },
            radio: {
                type: Boolean,
                default: false
            },
            boreholeId: {
                type: Number,
                default: 0
            },
            boreholeType: {
                type: String,
                default: ''
            }
        },
        setup(props, { emit }) {
            const table_data = reactive({
                general_table_data: [] as Array<{
                    id: number | string,
                    isChecked: any
                }>
            })

            const store = useStore()

            const setCheckbox = (checkbox) => {
                
                for(let i = 0; i < table_data.general_table_data.length; i++) {
                    if(table_data.general_table_data[i].id === checkbox.checkbox_id) {
                        table_data.general_table_data[i].isChecked = checkbox.checkbox_state
                    }
                }
            }

            const tabs_menu = reactive({
                menubar_list: [
                    {
                        text: "Гис",
                        id: 1,
                        request_name: 'WellLog'
                    },
                    {
                        text: "Ригис",
                        id: 2,
                        request_name: 'WellLogInterpretation'
                    },
                    {
                        text: "Пги",
                        id: 3,
                        request_name: 'FieldGeophysicalSurvey'
                    },
                    {
                        text: "Гди",
                        id: 4,
                        request_name: 'FlowTest'
                    },
                    {
                        text: "Гдк",
                        id: 5,
                        request_name: 'ProductionLogging'
                    },
                ],
                active_tab: 1
            })

            watch(() => props.table, () => {
                table_data.general_table_data = props.table.header?.content.concat(props.table.content?.content)
            })

            const buyingResearch = (research_id) => {
                
                axios
                    .post('/api/v1/Purchased/BoreholeLog', {
                        LogNameId: research_id,
                        PurchasedBoreholeId: props.boreholeId
                    })
                    .catch(err => {                 
                        store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    });
            }

            const sumOC = (content_values) => {           
                return content_values.reduce((accumulator, currentValue) => {return accumulator + Number(currentValue.price)}, 0)
            }

            const sumWfu = (content_values) => {
                return content_values.reduce((accumulator, currentValue) => {return accumulator + Number(currentValue.brigades)}, 0)
            }

            const sumTu = (content_values) => {
                return content_values.reduce((accumulator, currentValue) => {return accumulator + Number(currentValue.time)}, 0)
            }

            const selectMenu = (menu_id: number) => {
                tabs_menu.active_tab = menu_id
                emit('research_type', tabs_menu.menubar_list.find(menu => menu.id === menu_id)?.request_name)
            }

            return {
                table_data,
                tabs_menu,
                sumOC,
                sumWfu, 
                sumTu,
                selectMenu,
                buyingResearch,
                setCheckbox
            }

        },
        components: {
            'checkbox-cmp': checkboxCmp,
            'menubar-cmp': menubarCmp,
            'btn-cmp': btnCmp
        }
    })
