
    //разобраться с цветами и китайским языком, пока все дисплей нан
    import { defineComponent, reactive } from 'vue';
    import axios from 'axios';
    import { mapMutations } from 'vuex';

    export default defineComponent({
        props: {
            main_class: {
                type: String,
            },
            data: {
                type: Object,
                default: () => ({})
            }
        },
        setup(props) {            
            const header = reactive([
                {
                    text: 'Данные'
                },
                {
                    text: 'Значение'
                }
            ])

            const content = reactive({
                table: [] as any
            })

            const loader = reactive({
                value: false
            })

            axios
                .get(`Api/v1/Purchased/LithologicalReservoirParam/${props.data.order_id}`)
                .then(content_response => {
                    content.table = content_response.data
                })
                .finally(() => {
                    loader.value = true
                    // $root.setPreloaderState(false)
                })

            return {
                header,
                content,
                loader
            }
        },
        methods: {
            ...mapMutations('modal', ['closeModal'])
        },
    })

