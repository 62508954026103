
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        seismics: {
            type: Object,
            default: () => {}
        },
        coords: {
            type: Object,
            default: () => {}
        },
        data_type: {
            type: String,
            default: ''
        }
    }
})
