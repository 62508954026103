export default {
    namespaced: true,
    state: {
        polygon_reloaded: false
    },
    getters: {

    },
    mutations: {
        reloadData(state, value) {
            console.log(value);
            
            
            state.polygon_reloaded = value
        }
    },
}