export default {
    namespaced: true,
    state: {
        modalProps: null,
        modalComponent: null,
        modalShadow: true,
    },
    getters: {
        modalComponent(state) {
            return state.modalComponent;
        },
        modalProps(state) {
            return state.modalProps;
        },
        modalShadow(state) {
            return state.modalShadow;
        },
    },
    mutations: {
        openModal(state, data) {            
            state.modalComponent = data.newState
            if (data) {
                state.modalProps = data.props
            }
        },
        closeModal(state) {
            state.modalComponent = null
            state.modalProps = null
            state.modalShadow = false
        }
    }
}