
import { defineComponent, reactive } from 'vue'

export default defineComponent({
    props: {
        lines: {
            type: Object,
            dafault: () => {}
        },
        coords: {
            type: Object,
            default: () => {}
        },
        data_type: {
            type: String,
            default: ''
        }
    },  
    setup() {
        const communication = reactive([
            {
                direction: '' as string,
                y: 1 as number,
                x: 1 as number,
                serviceLines: [
                    {
                        color: '' as string
                    }
                ]
            }
        ])

        return {
            communication
        }
    },
    methods: {
        communicationMapping() {
            
        }
    //     directionLine(val) {
    //        for(let i = 0; i < this.communication.length; i++) {  
    //            if(this.communication[i].x === val.row && this.communication[i].y === val.col && this.communication[i].direction === val.direction) {
    //                if(this.communication[i].direction === 'Vertical' && this.communication[i].serviceLines.find(item => item.color === val.color)  && val.col !== 25) {
    //                    if(val.type === 'width') {
    //                        return 2
    //                    } else if(val.type === 'height') {
    //                        return 34
    //                    }
    //                }
    //                if(this.communication[i].direction === 'Horizontal' && this.communication[i].serviceLines.find(item => item.color === val.color && val.row !== 25)) {
    //                    if(val.type === 'width') {
    //                        return 34
    //                    } else if(val.type === 'height') {
    //                        return 2
    //                    } else {
    //                        return 0
    //                    }
    //                }
    //            }                    
    //        }
    //    },
    //    communicationMapping(val) {
    //         const matchingItems = this.communication.filter(item => item.x === val.row && item.y === val.col);
    //         const serviceLines = [];
    //         matchingItems.forEach(item => {
    //             item.serviceLines.forEach(line => {
    //                 serviceLines.push({
    //                     color: line.color,
    //                     direction: item.direction
    //                 });
    //             });
    //         });
    //         return serviceLines;
    //    },
    // },
    }
})
