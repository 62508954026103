
import { defineComponent, watch, reactive } from "vue"
import switchCmp from "../oil-switch/switch-cmp.vue"
import { useStore } from "vuex"
import axios from 'axios'
import { useRouter } from "vue-router"

interface DataTableItem {
    serviceLine?: string,
    boreholes?: string;
    model3D: string,
    research: string,
    arragement?: string,
    capex: string;
    currentOpex: string;
    eu: string;
    status: string;
    tu: string;
    wfu: string;
    profile?: string,
    maps?: string,
    statusColor: string,
    polygon_name?: string,
    color?: string,
    name: string,
    count: number,
    isRemovable,
    purchaseDate: string,
    id: number,
    isDeleteAvailable: boolean,
    opex: string,
    borehole: string,
    typeOfDelivery: boolean
}

export default defineComponent({
    props: {
        data_table: {
            type: Array as () => DataTableItem[],
            required: true,
        },
        active_table: {
            type: String,
            default: ''
        },
    },
    setup(props) {        
        const store = useStore()
        const router = useRouter()

        const visible_tooltip = reactive({
            value: ''
        })

        const table = reactive({
            content: props.data_table.map((
                { 
                    statusColor, 
                    model3D,
                    boreholes, 
                    arragement, 
                    serviceLine, 
                    maps, 
                    profile, 
                    capex,
                    count,
                    eu, status, tu, wfu, color, polygon_name, name, id,
                    purchaseDate,
                    isDeleteAvailable,
                    isRemovable,
                    research,
                    borehole,
                    opex,
                    typeOfDelivery
                }) => (
                    {
                        row_name: arragement ? arragement.split(' ').slice(0, -2).join(' ') : (serviceLine ? serviceLine.split(" ").slice(0, -1).join(" ") : (boreholes ? boreholes.split(" ").slice(0, 2).join(" ") : (profile ? profile.split("_").slice(0, -1).join(" ") : (name ? name : (model3D ? model3D : (research ? research : '')))))) || '',
                        name: boreholes ? boreholes : (serviceLine ? serviceLine : (arragement ? arragement : (maps ? maps : (profile ? profile : (model3D ? model3D : (research ? research : name)))))),
                        status,
                        tu: tu ? tu : '-',
                        capex: capex ? capex : '-',
                        opex: opex ? opex : '-',
                        wfu: wfu ? wfu : '-',
                        eu: eu ? eu : '-',
                        statusColor,
                        color,
                        purchaseDate,
                        polygon_name,
                        id,
                        count,
                        isDeleteAvailable,
                        isRemovable,
                        sub_name: borehole,
                        typeOfDelivery
                    }
                )), 
        })

        const status_modal_text = reactive({
            data: [] as any
        })
        
        const unique_names = reactive({
            content: table.content.reduce((acc, curr) => {
                if (!acc.includes(curr.row_name)) {
                    acc.push(curr.row_name);
                }
                
                return acc 

                }, [] as any
            )
        })

        const disable_switch = reactive({
            state: false,
            active: false
        })

        const header_table = [
            {
                name: "Наименование",
                status: "Статус",
                tu: "TU",
                capex: "CAPEX",
                opex: "OPEX",
                wfu: "WFU",
                eu: "EU",
                color: "Цвет"
            }
        ]


        // const table_tooltip = reactive({
        //     value: [] as Array <{
        //         text: string,
        //         tooltipId: number | null,
        //         tooltip: string
        //     }>
        // })
        
        const open_sub_row = reactive({
            state: "" as string
        })

        const polygon_table = reactive({
            table: props.data_table
        })

        const toggleSubRow = (row: any) => {
            open_sub_row.state = open_sub_row.state === row ? null : row
        }

        const countRowsWithSameName = (name) => {            
            return table.content.filter((row) => row.row_name === name).length;
        }
        const mappingRowName = (row_name) => {
            return table.content.filter(item => item.row_name === row_name)
        }

        const sumDataRow = (row, data_type) => {            
            table.content.filter(item => item.row_name === row)
    
            return table.content.filter(item => item.row_name === row).reduce((acc, cur) => (data_type !== 'statusColor' ? acc + Number(cur[data_type]) : acc + cur[data_type]), 0);
        }

        const openDetailedPage = (id) => {
            router.push(`card/${id}`)
        }

        const deletePolygon = (idx) => {
            localStorage.removeItem("images")
            polygon_table.table.splice(polygon_table.table.findIndex((item, index) => index === idx), 1)
            localStorage.setItem("images", JSON.stringify(polygon_table.table))

            store.commit('polygon/reloadData', true)

            // this.reloadData(true)
            // this.openNotification({
            //     props: {
            //         success: true,
            //         title: 'Успех!',
            //         desc: 'Полигон успешно добавлен!'
            //     }
            // })
        }
        const deleteSeismic = (id) => {
            axios   
                .delete(`api/v1/Purchased/Seismic/${id}`)
                .then(response_delete_sesmic => {
                    console.log(response_delete_sesmic)
                    // this.openNotification({
                    //     props: {
                    //         success: true,
                    //         title: 'Успех!',
                    //         desc: 'Сейсмика успешно удалена!'
                    //     }
                    // })
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'seismic')
                    
                })
                .catch(err => {  
                    console.log(err);
                                   
                    // store.commit('notification/openNotification', {
                    //     error: true,
                    //     title: 'Ошибка!',
                    //     desc: err.response.data
                    // })
                })
        }

        const deleteBorehole = (id) => {
            axios
                .delete(`api/v1/Purchased/Borehole/${id}`)
                .then(response_delete_borehole => {
                    console.log(response_delete_borehole);
                    // this.openNotification({
                    //     props: {
                    //         success: true,
                    //         title: 'Успех!',
                    //         desc: 'Скважина успешно удалена!'
                    //     }
                    // })
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'borehole')
                    
                })
                .catch(err =>{                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteMap = (id) => {
            axios
                .delete(`api/v1/Purchased/SurfaceImage/${id}`)
                .then(response_delete_borehole => {
                    console.log(response_delete_borehole);
                    // this.openNotification({
                    //     props: {
                    //         success: true,
                    //         title: 'Успех!',
                    //         desc: 'Карта успешно удалена!'
                    //     }
                    // })
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'maps')
                    
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteCommunication = (id) => {
            axios
                .delete(`api/v1/Purchased/ServiceLine/${id}`)
                .then(response_delete_communication => {
                    console.log(response_delete_communication.data);
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'communication')
                    
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteArrangment = (id) => {
            axios
                .delete(`api/v1/Purchased/ObjectOfArrangement/${id}`)
                .then(response_delete_arrangment => {
                    console.log(response_delete_arrangment.data);
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'arrangement')
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteModel3d = (id) => {
            axios
                .delete(`api/v1/Purchased/ModelProperty/${id}`)
                .then(response_delete_model_3d => {
                    console.log(response_delete_model_3d.data)
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'Model3D')
                    
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteResearch = (id) => {
            axios
                .delete(`api/V1/Purchased/BoreholeLog/${id}`)
                .then(response_delete_research => {
                    console.log(response_delete_research.data)
                })
                .finally(() => {
                    store.commit('request/setReloadState', 'Research')
                    
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        const deleteRow = (id) => {            
            switch(props.active_table) {
                case 'Borehole': 
                    deleteBorehole(id)
                    break

                case 'Seismic': 
                    deleteSeismic(id)
                    break

                case 'BuyMaps': 
                    deleteMap(id)
                    break
                
                case 'ServiceLine': 
                    deleteCommunication(id)
                    break

                case 'ObjectOfArrangement': 
                    deleteArrangment(id)
                    break
                
                case 'Model3D': 
                    deleteModel3d(id)
                    break

                case 'Research':
                    deleteResearch(id)
                    break

                default: 
                    break
            }

        }

        const openChangeStatusModal = (row_id: number, color: string) => {
            switch(props.active_table) {
                case 'Model3D':
                    break
                
                case 'Borehole':
                    axios 
                        .get(`/Views/Browser/Map/ObjectsAndResearches/BoreholeChangeStatus?boreholeId=${row_id}`)
                        .then((response_status: any) => {
                            status_modal_text.data = response_status.data as any
                        })
                        .finally(() => {
                            store.commit('modal/openModal', {
                                newState: 'statusModal',
                                monitoring_data: status_modal_text.data?.boreholeMonitoring,
                                props: {
                                    status_list: status_modal_text.data,
                                    monitoring_data: status_modal_text.data?.boreholeMonitoring?.map((monitoring_obj, idx) => {
                                    return {
                                        ...monitoring_obj,
                                        label: monitoring_obj.monitoringType === "Pressure" ? "<span>МПа</span>" : "<span>м<sup>3</sup>/сут</span>",
                                        formula: monitoring_obj.monitoringType === "Pressure" ? "<span>P<sub>заб</sub> =</span>" : (monitoring_obj.monitoringType === "Flowrate" ? "<span>Q =</span>" : "<span>i<sub>w</sub> =</span>"),
                                        id: idx + 1
                                    };
                                }),
                                    type: 'Borehole',
                                    id: row_id
                                }
                            })
                        })

                    break
                case 'ObjectOfArrangement': 
                    axios 
                        .get(`Views/Browser/Map/ObjectsAndResearches/ObjectOfArrangementChangeStatus?objectId=${row_id}`)
                        .then((response_status: any) => {
                            status_modal_text.data = response_status.data as any
                        })
                        .finally(() => {
                            store.commit('modal/openModal', {
                                newState: 'statusModal',
                                monitoring_data: status_modal_text.data?.boreholeMonitoring,
                                props: {
                                    status_list: status_modal_text.data,
                                    status_color: color,
                                    type: 'ObjectOfArrangement',
                                    id: row_id
                                }
                            })
                        })

                    break;
                default:
                    
            }
        }

        const changeTransportation = (val: any, id: any) => {  
            axios
                .patch(`api/v1/Purchased/Borehole/DeliveryType`, {
                    Id: id,
                    typeOfDelivery: val
                })
                .then(() => {
                    disable_switch.state = true
                })
                .catch(err => {    
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
                .finally(() => {
                    disable_switch.state = false
                    store.commit('request/setReloadState', 'borehole')
                })
        }

        watch(() => props.data_table, (props_table) => {
            table.content = props_table.map((
                { 
                    statusColor, 
                    boreholes, 
                    arragement, 
                    serviceLine, 
                    maps, 
                    profile, 
                    capex,
                    count,
                    currentOpex: opex, eu, status, tu, wfu, color, polygon_name, name, id,
                    isDeleteAvailable,
                    purchaseDate,
                    isRemovable,
                    model3D,
                    research,
                    borehole,
                    typeOfDelivery
                }) => (
                    {
                        row_name: arragement ? arragement.split(' ').slice(0, -2).join(' ') : (serviceLine ? serviceLine.split(" ").slice(0, -1).join(" ") : (boreholes ? boreholes.split(" ").slice(0, 2).join(" ") : (profile ? profile.split("_").slice(0, -1).join(" ") : (name ? name : (model3D ? model3D : (research ? research : '')))))) || '',
                        name: boreholes ? boreholes : (serviceLine ? serviceLine : (arragement ? arragement : (maps ? maps : (profile ? profile : (model3D ? model3D : (research ? research : name)))))),
                        status,
                        tu,
                        capex,
                        purchaseDate,
                        opex: opex ? opex : '-',
                        wfu,
                        eu,
                        statusColor,
                        color,
                        polygon_name,
                        id,
                        count,
                        isDeleteAvailable,
                        isRemovable,
                        sub_name: borehole,
                        typeOfDelivery
                    }
            ))

            unique_names.content = table.content.reduce((acc, curr) => {
                if (!acc.includes(curr.row_name)) {
                    acc.push(curr.row_name);
                }
                
                return acc 

                }, [] as any
            )
        })

        return { 
            unique_names, 
            table, 
            openChangeStatusModal, 
            disable_switch, 
            changeTransportation, 
            header_table,
            open_sub_row,
            polygon_table,
            visible_tooltip,
            deleteRow,
            toggleSubRow,
            countRowsWithSameName,
            mappingRowName,
            sumDataRow,
            deletePolygon,
            openDetailedPage,
            deleteResearch,
        };
    },
    // data() {
    //     return {
    //         header: [
    //             {
    //                 name: this.$root.static_text.table.name,
    //                 status: this.$root.static_text.table.status,
    //                 tu: this.$root.static_text.table.tu,
    //                 capex: this.$root.static_text.table.capex,
    //                 opex: this.$root.static_text.table.opex,
    //                 wfu: this.$root.static_text.table.wfu,
    //                 eu: this.$root.static_text.table.eu,
    //                 color: this.$root.static_text.table.color
    //             }
    //         ],
    //         open_sub_row: '',
    //         polygon_table: this.$props.data_table
    //     }
    // },
    components: {
        'switch-cmp': switchCmp
    },
    // methods: {
    //     ...mapMutations('polygon', ['reloadData']),
    //     ...mapMutations('request', ['updateMap']),
    //     ...mapMutations('notification', ['openNotification']),

    //     toggleSubRow(row) {
    //         this.open_sub_row = this.open_sub_row === row ? null : row
    //     },
    //     countRowsWithSameName(name) {            
    //         return this.table.content.filter((row) => row.row_name === name).length;
    //     },
    //     mappingRowName(row_name) {
    //         return this.table.content.filter(item => item.row_name === row_name)
    //     },
    //     sumDataRow(row, data_type) {            
    //         this.table.content.filter(item => item.row_name === row)
    
    //         return this.table.content.filter(item => item.row_name === row).reduce((acc, cur) => (data_type !== 'statusColor' ? acc + Number(cur[data_type]) : acc + cur[data_type]), 0);
    //     },
    //     deletePolygon(idx) {
    //         localStorage.removeItem("images")
    //         this.polygon_table.splice(this.polygon_table.findIndex((item, index) => index === idx), 1)
    //         localStorage.setItem("images", JSON.stringify(this.polygon_table))

    //         this.reloadData(true)
    //         this.openNotification({
    //             props: {
    //                 success: true,
    //                 title: 'Успех!',
    //                 desc: 'Полигон успешно добавлен!'
    //             }
    //         })
    //     },
    //     deleteSeismic(id) {
    //         axios   
    //             .delete(`api/v1/Purchased/Seismic/${id}`)
    //             .then(response_delete_sesmic => {
    //                 console.log(response_delete_sesmic)
    //                 this.openNotification({
    //                     props: {
    //                         success: true,
    //                         title: 'Успех!',
    //                         desc: 'Сейсмика успешно удалена!'
    //                     }
    //                 })
    //             })
    //             .finally(() => {
    //                 this.updateMap('seismic')
    //             })
    //             .catch(err => {
    //                 this.openNotification({
    //                     props: {
    //                         error: true,
    //                         title: 'Ошибка!',
    //                         desc: err.response.data
    //                     }
    //                 })
    //             })
    //     },
    //     deleteBorehole(id) {
    //         axios
    //             .delete(`api/v1/Purchased/Borehole/${id}`)
    //             .then(response_delete_borehole => {
    //                 console.log(response_delete_borehole);
    //                 this.openNotification({
    //                     props: {
    //                         success: true,
    //                         title: 'Успех!',
    //                         desc: 'Скважина успешно удалена!'
    //                     }
    //                 })
    //             })
    //             .finally(() => {
    //                 this.updateMap('borehole')
    //             })
    //             .catch(err => {
    //                 this.openNotification({
    //                     props: {
    //                         error: true,
    //                         title: 'Ошибка!',
    //                         desc: err.response.data
    //                     }
    //                 })
    //             })
    //     },
    //     deleteMap(id) {
    //         axios
    //             .delete(`api/v1/Purchased/SurfaceImage/Part/${id}`)
    //             .then(response_delete_borehole => {
    //                 console.log(response_delete_borehole);
    //                 this.openNotification({
    //                     props: {
    //                         success: true,
    //                         title: 'Успех!',
    //                         desc: 'Карта успешно удалена!'
    //                     }
    //                 })
    //             })
    //             .finally(() => {
    //                 this.updateMap('buy_maps')
    //             })
    //             .catch(err => {
    //                 this.openNotification({
    //                     props: {
    //                         error: true,
    //                         title: 'Ошибка!',
    //                         desc: err.response.data
    //                     }
    //                 })
    //             })
    //     },
    //     deleteCommunication(id) {
    //         axios
    //             .delete(`https://main.x.oilcase.ru/api/v1/Purchased/ServiceLine/${id}`)
    //             .then(response_delete_communication => {
    //                 console.log(response_delete_communication.data);
    //             })
    //             .finally(() => {
    //                 this.updateMap('communication')
    //             })
    //             .catch(err => {
    //                 this.openNotification({
    //                     props: {
    //                         error: true,
    //                         title: 'Ошибка!',
    //                         desc: err.response.data
    //                     }
    //                 })
    //             })
    //     },
    //     deleteArrangment(id) {
    //         axios
    //             .delete(`https://main.x.oilcase.ru/api/v1/Purchased/ObjectOfArrangement/${id}`)
    //             .then(response_delete_communication => {
    //                 console.log(response_delete_communication.data);
    //             })
    //             .finally(() => {
    //                 this.updateMap('communication')
    //             })
    //             .catch(err => {
    //                 this.openNotification({
    //                     props: {
    //                         error: true,
    //                         title: 'Ошибка!',
    //                         desc: err.response.data
    //                     }
    //                 })
    //             })
    //     },
    //     deleteRow(id) {            
    //         switch(this.$props.active_table) {
    //             case 'Borehole': 
    //                 this.deleteBorehole(id)
    //                 break;

    //             case 'Seismic': 
    //                 this.deleteSeismic(id)
    //                 break;

    //             case 'BuyMaps': 
    //                 this.deleteMap(id)
    //                 break;
                
    //             case 'ServiceLine': 
    //                 this.deleteCommunication(id)
    //                 break;

    //             case 'ObjectOfArrangement': 
    //                 this.deleteArrangment(id)
    //                 break;

    //             default: 
    //                 break;
    //         }

    //     }
    // },
});
