import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_cmp = _resolveComponent("radio-cmp")!
  const _component_table_cmp = _resolveComponent("table-cmp")!
  const _component_button_cmp = _resolveComponent("button-cmp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.main_class}__seismic`)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.main_class}__seismic__title`)
    }, _toDisplayString(_ctx.$root.static_text.modal.seismic.title), 3),
    (_ctx.data && _ctx.data.coords)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(`${_ctx.main_class}__seismic__coords`)
        }, _toDisplayString(_ctx.$root.static_text.modal.seismic.coords) + ": x" + _toDisplayString(_ctx.data.coords.x) + " y" + _toDisplayString(_ctx.data.coords.y), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__seismic__coords`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.seismic.seismic_list, (seismic) => {
        return (_openBlock(), _createBlock(_component_radio_cmp, {
          key: seismic.id,
          class: _normalizeClass(`${_ctx.main_class}__seismic__coords__radio`),
          text: seismic.text,
          id: seismic.id,
          active: _ctx.active_seismic.value,
          onSetId: _ctx.setSeismicType
        }, null, 8, ["class", "text", "id", "active", "onSetId"]))
      }), 128))
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.GlobalLocalization, (item) => {
      return (_openBlock(), _createBlock(_component_table_cmp, {
        key: item,
        view: item.type,
        table: item.content
      }, null, 8, ["view", "table"]))
    }), 128)),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__seismic__btns`)
    }, [
      _createVNode(_component_button_cmp, {
        background: false,
        text: _ctx.$root.static_text.buttons.cancel,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
      }, null, 8, ["text"]),
      _createVNode(_component_button_cmp, {
        onClick: _ctx.setLine,
        text: _ctx.$root.static_text.buttons.add
      }, null, 8, ["onClick", "text"])
    ], 2)
  ], 2))
}