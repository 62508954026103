import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_cmp = _resolveComponent("button-cmp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.main_class}__restore`)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.main_class}__restore-title`)
    }, "Восстановление доступа", 2),
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.main_class}__restore-text`)
    }, [
      _createTextVNode("Обратитесь в нашу "),
      _createElementVNode("a", {
        class: _normalizeClass(`${_ctx.main_class}__restore-text-tg`),
        href: "https://t.me/oilcase_support",
        target: "_blank"
      }, "службу поддержки в Telegram", 2),
      _createTextVNode(" или на "),
      _createElementVNode("a", {
        href: "mailto:support@oilcase.com",
        class: _normalizeClass(`${_ctx.main_class}__restore-text__email`)
      }, "support@oilcase.com", 2),
      _createTextVNode(" вам помогут восстановить доступ. ")
    ], 2),
    _createVNode(_component_button_cmp, {
      text: 'Назад',
      class: _normalizeClass(`${_ctx.main_class}__restore-back`),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal({
                newState: 'authModal'
            })))
    }, null, 8, ["class"])
  ], 2))
}