
import { reactive, watch } from 'vue'

    export default {
        props: {
            list: {
                type: Array,
                default: () => {}
            },
            select_values: {
                type: String,
                default: 'Выберите скважину'
            }
        },
        setup(props, { emit }) {
            const choose = reactive({
                value: props.select_values 
            })

            watch(() => props.select_values, () => {
                if(props.select_values) {
                    choose.value = props.select_values
                }
                
            })

            const open_list = reactive({
                value: false
            })

            const openListState = () => {
                open_list.value = !open_list.value
            }

            const selectBorehole = (val) => {
                choose.value = val.name
                open_list.value = false
                emit('boreholeId', {id: val.id, type: val.type})
            }

            return {
                choose,
                open_list,
                openListState,
                selectBorehole
            }
        },
    }
