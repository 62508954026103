
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router';
import iconCmp from "../../ui-components/oil-icons/icons-cmp.vue"
import buttonCmp from "../../ui-components/oil-button/button-cmp.vue"

export default defineComponent({
    setup() {
        const router = useRouter()

        const visible_header = reactive({
            value: true as Boolean
        })

        const visible_tooltip = reactive({
            value: false as Boolean | String
        })

        return {
            router,
            visible_header,
            visible_tooltip,
        }
    },  
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        // total_assets() {                 
        //     const current_assets = this.$props.data.items[0];            
                                    
        //     current_assets.items.map((item, idx) => ({ ...item, ...this.$root.total_resources_icons[idx]}))
        //     current_assets.subMenu.map((item, idx) => ({ ...item, ...this.$root.total_resources_icons[idx]}))

        //     return [
        //         current_assets.items.map((item, idx) => ({ ...item, ...this.$root.total_resources_icons[idx]})), 
        //         current_assets.subMenu.map((item, idx) => ({ ...item, ...this.$root.total_resources_icons[idx]}))
        //     ];
        // },
        // extracted_resources() {
        //     const extracted_resources = this.$props.data.items[1];            
                                    
        //     extracted_resources.items.map((item, idx) => ({ ...item, ...this.$root.extracted_resources_icons[idx]}))
        //     extracted_resources.subMenu.map((item, idx) => ({ ...item, ...this.$root.extracted_resources_icons[idx]}))

        //     return [
        //         extracted_resources.items.map((item, idx) => ({ ...item, ...this.$root.extracted_resources_icons[idx]})), 
        //         extracted_resources.subMenu.map((item, idx) => ({ ...item, ...this.$root.extracted_resources_icons[idx]}))
        //     ];
        // }
        total_assets() {
            const main_values = this.$props.data.items[0].items
            
            return main_values.map((item: any, idx: any) => ({ ...item, ...this.$root.total_resources_icons[idx]}))
        },
        extracted_resources() {
            const delta_values = this.$props.data.items[0].subMenu

            return delta_values.map((item: any, idx: any) => ({ ...item, ...this.$root.extracted_resources_icons[idx]}))
        },
        current_values() {
            const secondary_values = this.$props.data.items[1].items 

            return secondary_values.map((item: any, idx: any) => ({ ...item, ...this.$root.total_resources_icons[idx]}))
        }
    },
    components: {
        iconCmp,
        buttonCmp
    },
    methods: {
        expandHeader() {
            this.visible_header.value = true 
        }
    },
    mounted() {
        let prev_scroll_posititon = 100

        document.addEventListener('scroll', () => {
            let current_scroll_posititon = window.scrollY            

            if(current_scroll_posititon > prev_scroll_posititon) {
                this.visible_header.value = false 
            } else {
                this.visible_header.value = true 
            }   
        })
    }
})
