
import Grid from "../../components/ui-components/oil-grid/grid-cmp.vue"
import Object from "../../components/ui-components/oil-object/object-cmp.vue"
import { reactive, defineComponent } from "vue"

export default defineComponent({
    setup() {
        const selected_menu = reactive({
            state: ''
        })

        const setMenuValue = (val) => {           
            selected_menu.state = val
        }

        return {
            selected_menu,
            setMenuValue
        }
    },
    data() {
        return {

        }
    },
    components: {
        'grid-cmp': Grid,
        'object-cmp': Object
    },

})
