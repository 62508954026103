
import { defineComponent } from 'vue'

interface ContextComponent {
    // eslint-disable-next-line no-unused-vars
    $emit(event: 'select-tab', tab_id: number): void
}

export default defineComponent({
     props: {
        text: {
            type: String,
            default: "",
        },
        id: {
            type: Number,
            default: 1,
        },
        active: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        selectTab(this: ContextComponent, tab_id: number) {            
            this.$emit('select-tab', tab_id)
        }
    }
});
