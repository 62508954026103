import axios from 'axios'

export default {
    namespaced: true,
    state: {
        token: "" as string,
        isAuth: false as boolean,
        update: '' as string,
        updateInterval:false,
        host: process.env.NODE_ENV === 'production' ? '/' : 'https://main.x.oilcase.ru80' as string,
        remember: false as boolean,
        reload: '' as string
    },
    getters: {
        reload(state) {
            return state.reload
        }
    },
    mutations: {
        setReloadState(state, type_reload: string) {
            
            state.reload = type_reload
        },
        setToken(state: { token: string }, data: any) {
            state.token = data
        },
        logIn(state: { isAuth: boolean, token: string }) {
            state.isAuth = true
        },
        logOut(state: {isAuth: boolean}) {
            state.isAuth = false
        },
        updateMap(state: { update: string }, update_type: string) {                        
            state.update = update_type
        },
        updateIntervalDrilling(state: {updateInterval: boolean}) {
            state.updateInterval = true 
            setTimeout(() => {
                state.updateInterval = false
            }, 1000)
        },
        rememberMe(state: { remember: boolean }) {
            state.remember = true
        },
        unRemember(state: { remember: boolean }) {
            state.remember = false
        },

    },
    actions: {
        async getToken( { commit, state }: any, login: object) {
            
            await axios
                .post(`${state.host}api/v1/Login`, login)
                .then((resp) => {
                    if (resp.data) {
                        commit('setToken', resp.data)
                        commit('logIn')
                    }
                })
        },
        async getData(state: {state: {host: string; token: string }}, url: string) {                
            if (state.state.token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${state.state.token}`
            }             
            
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
            try {
                const resp = await axios.get(`${state.state.host}${url}`)
                return resp.data
            } catch (err) {
                return err
            }

        },
        async setData(state, data) {                        
            try {
                const resp = await axios.post(`${state.state.host}${data.url}`, data.body)
                return resp.data
            } catch (err) {
                return err
            }
            // await axios
            //     .post(`${state.state.host}${data.url}`, data.body)
            //     .then(resp => {
            //         return resp
            //     })
            //     .catch(err => {
            //         return err
            //     })
        },
    }
}