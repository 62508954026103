import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["y", "x"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["oil-arrangement", {'_selected-tab': _ctx.data_type === 'arrangement'}])
  }, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (row) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: row }, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (column) => {
          return _createElementVNode("div", {
            key: column,
            class: "oil-arrangement__cell",
            y: column,
            x: row,
            style: _normalizeStyle({background: _ctx.mappingArragement(row, column)})
          }, null, 12, _hoisted_1)
        }), 64))
      ], 64))
    }), 64))
  ], 2))
}