
import { defineComponent, reactive, watch, computed } from 'vue'
import detailedCell from './detailed-cell-cmp/detailed-cell-cmp.vue'
import boreholeProjection from './borehole-cmp/borehole-cmp.vue'
import arrangementProjection from './arrangement-cmp/arrangement-cmp.vue'
import seismicProjection from "./seismic-cmp/seismic-cmp.vue"
import communicationProjection from './communication-cmp/communication-cmp.vue'
import mapProjection from './map-cmp/map-cmp.vue'
import polygonImages from './polygon-cmp/polygon-cmp.vue'
import axios from 'axios'
// import { mapMutations, mapState } from 'vuex'
import { useStore } from 'vuex'
// import Panzoom from 'panzoom'
  
export default defineComponent({
    props: {
        data_type: {
            type: String,
            default: 'arrangement'
        },
        maps: {
            type: Array,
            default: () => [
                ''
            ]
        },
        active_map: {
            type: Array,
            default: () => []
        }
    },
    // watch: {
    //     data_type(val) {
    //         if(val === 'communication') {
    //             const url = 'Views/Browser/Map/ServiceLine/Available'

    //             axios
    //                 .get(url)
    //                 .then(response_available => {
    //                     this.plane_communication.values = response_available.data
    //                 })
    //         }
    //     },
    //     update() {
    //         switch (this.update) {
    //             case 'borehole':
    //                 axios   
    //                     .get(`Views/Browser/Map/Map/WellPad?x=${this.region.coords.x}&y=${this.region.coords.y}`)
    //                     .then(borehole_response => {
    //                         this.objects_inner_cell.borehole = borehole_response.data
    //                     })

    //                 axios
    //                     .get('Views/Browser/map/map/MapObject/borehole')
    //                     .then(resp_borehole => {
    //                         this.boreholes_dots.boreholes = resp_borehole.data
    //                     })
    //                     .finally(() => {
    //                         this.updateMap('')
    //                     })
    //                 break;
    //             case 'seismic':
    //                 axios
    //                     .get('api/v1/LithologicalData/seismic')
    //                     .then(resp_seismic => {
    //                         this.seismic_lines.seismic = resp_seismic.data
    //                     })
    //                 this.updateMap('')
    //                 break; 
    //             case 'buy_maps':
    //                 this.buying_map.initial_point = 0
    //                 this.buying_map.end_point = 0
    //                 this.build_map.build_cells = []
    //                 this.updateMap('')
    //                 break;    
    //             default:
    //                 break;
    //         }     
    //     },
    //     clear_coords() {
    //         console.log('change');
            
    //         this.buying_map.initial_point = 0
    //         this.buying_map.end_point = 0
    //         this.build_map.build_cells = []
    //         this.updateBuildMap(false)
    //     }
    // },
    setup(props, { emit }) {
        const store = useStore()

        const plane_communication = reactive({
            values: [] as Array<{
                x: number,
                y: number
            }>
        })

        const visible_map = reactive({
            id: '' as string
        })

        const build_map = reactive({
            build_cells: [] as any
        })

        const communication_lines = reactive({
            lines: [] as Array<{
                x: Number,
                y: Number,
                segment: boolean,
                direction: String,
                serviceLines: Array<{
                    type: String,
                    status: String,
                    color: String,
                    segment: boolean,
                }>
            }>
        })

        const arrangement_map = reactive({
            arrangement: [] as Array<{
                x: Number,
                y: Number,
                color: [
                    {
                        color: String,
                        x: Number,
                        y: number
                    }
                ]
            }>
        })

        const boreholes_dots = reactive({
            boreholes: [] as Array<{
                x: Number,
                y: Number,
                color: Array<String>,
                type: string
            }>
        })

        const communication_setting = reactive({
            start_communication: {
                x: 0 as number,
                y: 0 as number
            },
            end_communication: {
                x: 0 as number,
                y: 0 as number
            },
            direction_communication: '' as string,
            line_length: 0 as number,
            segment: false as boolean
        })

        const seismic_lines = reactive({
            seismic: [] as Array<{
                startX: Number,
                endX: Number,
                startY: Number,
                endY: Number
            }>
        })

        const region = reactive({
            coords: {
                x: 0,
                y: 0
            } as {
                x: number,
                y: number
            }
        })
        
        const objects_inner_cell = reactive({
            arrangement: [] as Array<{
                color: String,
                description: String,
                id: Number,
                imageLink: String,
                key: String,
                xInCell: String,
                yInCell: String
            }>,
            borehole: {
                boreholes: [] as Array<{
                    name: string,
                    orderNumber: number,
                    status: string,
                    type: string,
                }>,
                id: Number,
                isVertical: Boolean,
                line: Number
            } 
                
        })

        const buying_map = reactive({
            activity: false as boolean,
            initial_point: 0 as any,
            end_point: 0 as any
        })

        const map_urls = [
            {
                url: 'Views/Browser/Map/Map/MapObject/ServiceLine'
            }, 
            {
                url: 'Views/Browser/map/map/MapObject/object'
            },
            {
                url: 'Views/Browser/map/map/MapObject/borehole'
            },
            {
                url: 'api/v1/LithologicalData/seismic'
            }
        ] as Array<{
            url: string
        }>
        // const map_urls = [
        //     {
        //         url: 'http://localhost:8080/api/test.json'
        //     }, 
        //     {
        //         url: 'Views/Browser/map/map/MapObject/object'
        //     },
        //     {
        //         url: 'Views/Browser/map/map/MapObject/borehole'
        //     },
        //     {
        //         url: 'api/v1/LithologicalData/seismic'
        //     }
        // ] as Array<{
        //     url: string
        // }>

        const reload_data = computed(() => {
            return store.getters['modal/modalComponent']
        })      

        const type_communication = reactive({
            value: 'Road'
        })

        const first_step = reactive({
            value: 0
        })

        watch(() => store.getters['building/typeCommunication'], () => {
            type_communication.value = store.getters['building/typeCommunication']
        })        

        watch(() => props.data_type, () => {
            if(props.data_type === 'communication') {
                const url = `Views/Browser/Map/ObjectsAndResearches/AddModal?x=${region.coords.x}&y=${region.coords.y}`
                axios
                    .get(url)
                    .then(response_communication_list => {
                        store.commit('modal/openModal', {
                            newState: 'communicationModal',
                            props: {
                                data: response_communication_list.data,
                            }
                        })
                    })
            }
        })
        
        // watch(reload_data, () => {
        //     switch (props.data_type) {
        //         case 'arrangement':
        //             axios 
        //                 .get(map_urls[1].url)   
        //                 .then(arrangement_response => {
        //                     arrangement_map.arrangement = arrangement_response.data
        //                 })
        //                 .finally(() => {
        //                     openDetailedMap(region.coords.x, region.coords.y)
        //                 })

        //             break;
        //         case 'borehole': 
        //             axios 
        //                 .get(map_urls[2].url)   
        //                 .then(borehole_response => {
        //                     boreholes_dots.boreholes = borehole_response.data
        //                 })
        //                 .finally(() => {
        //                     openBoreholeDetailed(region.coords.x, region.coords.y)
        //                 })
        //             break;    
        //         case 'seismic': 
        //             axios 
        //                 .get(map_urls[3].url)   
        //                 .then(seismic_response => {
        //                     seismic_lines.seismic = seismic_response.data
        //                 })
        //                 .finally(() => {
        //                     openSeismicMenu(region.coords.x, region.coords.y)
        //                 })

        //             break;    
        //         case 'communication': 
        //             if(store.getters['building/build']) {
        //                 axios 
        //                     .get(map_urls[0].url)   
        //                     .then(communication_response => {
        //                         communication_lines.lines = communication_response.data 
        //                     })
        //                     .finally(() => {
        //                         constructionOfCommunications(region.coords.x, region.coords.y)
        //                     })
        //                 }

        //             break;
        //         default:
        //             break;
        //     }
        // })

        watch(() => store.getters['request/reload'], (new_state) => {
            switch (new_state) {
                case 'arrangement':
                    axios 
                        .get(map_urls[1].url)   
                        .then(arrangement_response => {
                            arrangement_map.arrangement = arrangement_response.data
                        })
                        .finally(() => {
                            openDetailedMap(region.coords.x, region.coords.y)
                            store.commit('request/setReloadState', '')
                        })


                    break;
                case 'borehole': 
                    axios 
                        .get(map_urls[2].url)   
                        .then(borehole_response => {
                            boreholes_dots.boreholes = borehole_response.data
                        })
                        .finally(() => {
                            openBoreholeDetailed(region.coords.x, region.coords.y)
                            store.commit('request/setReloadState', '')
                        })

                    break;    
                case 'seismic': 
                    axios 
                        .get(map_urls[3].url)   
                        .then(seismic_response => {
                            seismic_lines.seismic = seismic_response.data
                        })
                        .finally(() => {
                            store.commit('request/setReloadState', '')
                        })


                    break;    
                case 'communication': 
                    
                    if(store.getters['building/build']) {
                        axios 
                            .get(map_urls[0].url)   
                            .then(communication_response => {
                                communication_lines.lines = communication_response.data 
                            })
                            .finally(() => {
                                store.commit('building/setBuilding', false)
                                constructionOfCommunications(region.coords.x, region.coords.y)
                            })
                    } else {
                        axios 
                            .get(map_urls[0].url)   
                            .then(communication_response => {
                                communication_lines.lines = communication_response.data 
                            })
                            .finally(() => {
                                store.commit('building/setBuilding', false)
                            })
                    }


                    break;
                default:
                    break;
            }
        })

        const mapMapping = (x: number, y: number) => {
            if( x === buying_map.initial_point.x && y === buying_map.initial_point.y || 
                x === buying_map.end_point.x && y === buying_map.end_point.y ||
                x >= buying_map.initial_point.x && x <= buying_map.end_point.x &&  y >= buying_map.initial_point.y && y <= buying_map.end_point.y  ||
                y === buying_map.initial_point.y && x >= buying_map.initial_point.x && x <= buying_map.end_point.x ) {
                build_map.build_cells.push({build_map: true})
            }
            return (
                x === buying_map.initial_point.x && y === buying_map.initial_point.y || 
                x === buying_map.end_point.x && y === buying_map.end_point.y ||
                x >= buying_map.initial_point.x && x <= buying_map.end_point.x &&  y >= buying_map.initial_point.y && y <= buying_map.end_point.y  ||
                y === buying_map.initial_point.y && x >= buying_map.initial_point.x && x <= buying_map.end_point.x 
            )
        }
        const mappingCommunicationLine = (x: number, y: number) => {  
            let x1_h = communication_lines.lines
                .filter(line => line.x === x + 1 && line.y === y && line.direction ===  'Horizontal')
                ?.map(line => line.serviceLines.map(service => service.type))[0] ?? []

            let x1_v = communication_lines.lines
                .filter(line => line.x === x  && line.y === y + 1 && line.direction ===  'Vertical')
                ?.map(line => line.serviceLines.map(service => service.type))[0] ?? []

            let result = communication_lines.lines.filter(line => line.x === x && line.y === y)
            
            let empty_line = {type: null, status: null, color: null, lastCell: false}
            return [
                {
                    x: x,
                    y : y,
                    direction : "Horizontal",
                    serviceLines: result.find(line => line.direction === 'Horizontal')?.serviceLines.map(line => x1_h.find(l => l === line.type) ? line : empty_line)
                },         {
                    x: x,
                    y : y,
                    direction : "Vertical",
                    serviceLines: result.find(line => line.direction === 'Vertical')?.serviceLines.map(line => x1_v.find(l => l === line.type) ? line : empty_line)

                },
            ]
        }

        const mappingArrangement = (x: number, y:number) => {
            return arrangement_map.arrangement.find(arrangement => arrangement.x === x && arrangement.y === y)?.color
        }
        const mappingBorehole = (x: number, y: number) => {
            return boreholes_dots.boreholes.find(borehole => borehole.x === x && borehole.y === y)?.color
        }

        const openDetailedMap = (x: number, y: number) => {
            const url = `Views/Browser/Map/Map/Arrangement?X=${x}&Y=${y}`

            axios
                .get(url)
                .then(detailed_cell_response => {
                    objects_inner_cell.arrangement = detailed_cell_response.data
                })
        }
        const initialMap = (x: number, y: number) => {
            if(!buying_map.initial_point) {
                buying_map.initial_point = {x: x, y: y}
            } else if(buying_map.initial_point) {
                buying_map.end_point = {x: x, y: y}
                setTimeout(() => {
                    store.commit('modal/openModal' ,{
                        newState: 'buildMapModal',
                        props: {
                            map_length: build_map.build_cells.length,
                            buying_map: buying_map
                        }
                    })
                }, 500)
            }
        }

        const setEndPoint = (val: any) => {
            buying_map.end_point = val
        }

        const openBoreholeDetailed = (x:number, y:number) => {
            const url = `Views/Browser/Map/Map/WellPad?x=${x}&y=${y}`

            axios
                .get(url)
                .then(borehole_response => {
                    objects_inner_cell.borehole = borehole_response.data
                })
        } 


        const buildingCommunication = (x: number, y: number) => {
            return plane_communication.values.some(item => item.x === x && item.y === y)
        }

        // const setCommunicationLine = (x: number, y: number) => {
        //     if(store.getters['building/build']) {    
        //         // if() {

        //         // }         
        //         if(communication_setting.start_communication.x > x
        //         && communication_setting.start_communication.y === y) {                        
        //             communication_setting.direction_communication = "Left"    
        //             communication_setting.line_length = communication_setting.start_communication.x - x 
        //         } else if(communication_setting.start_communication.x < x 
        //         && communication_setting.start_communication.y === y) {
        //             communication_setting.direction_communication = "Right" 
        //             communication_setting.line_length = x - communication_setting.start_communication.x
        //         } else if(communication_setting.start_communication.y > y
        //         && communication_setting.start_communication.x === x) {
        //             communication_setting.direction_communication = "Down"
        //             communication_setting.line_length = y - communication_setting.start_communication.y
        //         } else if(communication_setting.start_communication.y < y 
        //         && communication_setting.start_communication.x === x) {
        //             communication_setting.direction_communication = "Up"
        //             communication_setting.line_length = y - communication_setting.start_communication.y
        //         }
                
        //         const url = "api/test?key=post"
                
        //         axios
        //             .post(url, {
        //                 "key": type_communication.value,
        //                 "Direction": communication_setting.direction_communication,
        //                 "X": communication_setting.start_communication.x,
        //                 "Y": communication_setting.start_communication.y,
        //                 "LineLengthInCellCount": Math.abs(communication_setting.line_length),
        //                 "LastCell": (communication_setting.line_length - 1) ? 'last_dots' : null
        //             })
        //             .then(() => {
        //                 emit('building', false)
        //                 store.commit('building/setBuilding', false)
        //             })
        //             .finally(() => {
        //                 store.commit('request/setReloadState', 'communication')
        //                 axios 
        //                     .get(map_urls[0].url)   
        //                     .then(communication_response => {
        //                         communication_lines.lines = communication_response.data 
        //                     })
        //                     .finally(() => {
        //                         store.commit('building/setBuilding', false)
        //                         store.commit('request/setReloadState', '')
        //                     })
        //             })
        //     }
        // }
        watch(() => store.getters['building/build'], () => {
            if(store.getters['building/build']) {
                // first_step.value = true
                constructionOfCommunications(0, 0)
            }
        })

        // watch(() => first_step.value, (prev_value) => {
        //     if(prev_value) {
        //         first_step.value = false 
        //     }
        // })

        const setCommunicationLine = (x: number, y: number) => {
                constructionOfCommunications(x, y)
            
                if(store.getters['building/build'] && first_step.value === 2) {    
                    if(communication_setting.start_communication.x > x
                    && communication_setting.start_communication.y === y) {                        
                        communication_setting.direction_communication = "Left"    
                        communication_setting.line_length = communication_setting.start_communication.x - x 
                    } else if(communication_setting.start_communication.x < x 
                    && communication_setting.start_communication.y === y) {
                        communication_setting.direction_communication = "Right" 
                        communication_setting.line_length = x - communication_setting.start_communication.x
                    } else if(communication_setting.start_communication.y > y
                    && communication_setting.start_communication.x === x) {
                        communication_setting.direction_communication = "Down"
                        communication_setting.line_length = y - communication_setting.start_communication.y
                    } else if(communication_setting.start_communication.y < y 
                    && communication_setting.start_communication.x === x) {
                        communication_setting.direction_communication = "Up"
                        communication_setting.line_length = y - communication_setting.start_communication.y   
                    }
                    
                    const url = "api/v1/Purchased/ServiceLine"
                        axios
                            .post(url, {
                                "key": type_communication.value,
                                "Direction": communication_setting.direction_communication,
                                "X": communication_setting.start_communication.x,
                                "Y": communication_setting.start_communication.y,
                                "LineLengthInCellCount": Math.abs(communication_setting.line_length),
                                "LastCell": (communication_setting.line_length - 1) ? 'last_dots' : null
                            })
                            .then(() => {
                                emit('building', false)
                                store.commit('building/setBuilding', false)
                            })
                            .catch(err => {                 
                                store.commit('notification/openNotification', {
                                    error: true,
                                    title: 'Ошибка!',
                                    desc: err.response.data
                                })
                            })
                            .finally(() => {
                                store.commit('request/setReloadState', 'communication')
                                store.commit('building/setBuilding', false)
                                axios 
                                    .get(map_urls[0].url)   
                                    .then(communication_response => {
                                        communication_lines.lines = communication_response.data 
                                    })
                                    .finally(() => {
                                        store.commit('building/setBuilding', false)
                                        store.commit('request/setReloadState', '')
                                        
                                    })
                    })
                
                }


        }

        const constructionOfCommunications = (x: number, y: number) => {
            
            if(store.getters['building/build']) {
                const url = `Views/Browser/Map/ServiceLine/Available?x=${x}&y=${y}&serviceLine=${type_communication.value}&isFirstPoint=${first_step.value === 0 ? true : false}`

                if(first_step.value === 0) {
                    axios
                        .get(url)
                        .then(communication_response => {
                            plane_communication.values = communication_response.data
                        }) 
                        .finally(() => {
                            first_step.value = 1
                        })
                } else if(first_step.value === 1) {
                    axios
                        .get(url)
                        .then(communication_response => {
                            plane_communication.values = communication_response.data
                        }) 
                        .finally(() => {
                            communication_setting.start_communication = {x, y}
                            first_step.value = 2
                            
                            // const url = `Views/Browser/Map/ObjectsAndResearches/AddModal?x=${x}&y=${y}`
                            // axios
                            //     .get(url)
                            //     .then(response_communication_list => {
                            //         store.commit('modal/openModal', {
                            //             newState: 'communicationModal',
                            //             props: {
                            //                 data: response_communication_list.data,
                            //             }
                            //         })
                            //     })
                        })
                } else if(first_step.value === 2) {
                    axios
                        .get(url)
                        .then(communication_response => {
                            plane_communication.values = communication_response.data
                        }) 
                        .finally(() => {
                            first_step.value = 0
                            store.commit('building/setBuilding', false)
                            emit('build_communication', false)
                            // const url = `Views/Browser/Map/ObjectsAndResearches/AddModal?x=${x}&y=${y}`
                            // axios
                            //     .get(url)
                            //     .then(response_communication_list => {
                            //         store.commit('modal/openModal', {
                            //             newState: 'communicationModal',
                            //             props: {
                            //                 data: response_communication_list.data,
                            //             }
                            //         })
                            //     })
                        })
                }
                // axios
                //     .get(url)
                //     .then(communication_response => {
                //         plane_communication.values = communication_response.data
                //     }) 
                //     .finally(() => {
                //         if(first_step.value === 0) {
                //             first_step.value = 1
                //         } else if(first_step.value === 1) {
                //             first_step.value = 2
                //         }
                //         // const url = `Views/Browser/Map/ObjectsAndResearches/AddModal?x=${x}&y=${y}`
                //         // axios
                //         //     .get(url)
                //         //     .then(response_communication_list => {
                //         //         store.commit('modal/openModal', {
                //         //             newState: 'communicationModal',
                //         //             props: {
                //         //                 data: response_communication_list.data,
                //         //             }
                //         //         })
                //         //     })
                //     })
            }
        }
        
        const openSeismicMenu = (x: number, y: number) => {            
            const url = 'Views/Browser/Map/Purchase/Seismic'

            axios
                .get(url)
                .then(response_seismic => {
                    store.commit('modal/openModal',{
                        newState: 'seismicModal',
                        props: {
                            data: response_seismic.data.table,
                            type: props.data_type,
                            coords: {
                                x: x,
                                y: y
                            },
                        }
                    })
                })
        }

        const activityOnCell = (x: number, y: number) => {
            region.coords.x = x
            region.coords.y = y
            
            switch (props.data_type) {
                case 'arrangement':
                    openDetailedMap(x, y)
                    break;

                case 'borehole':
                    openBoreholeDetailed(x, y)
                    break;
                    
                case 'communication': 
                    constructionOfCommunications(x, y)
                    break;

                case 'seismic':
                    openSeismicMenu(x, y)
                    break;

                case 'maps': 
                    initialMap(x, y)

                    break;
                default:
                    break;
            }
        }

        // axios
        //     .get('http://localhost:8080/api/test.json')
        //     .then(response => {
        //         console.log(response);
                
        //     })


        axios.all(map_urls.map((endpoint) => 
            axios
                .get(endpoint.url)))
                .then(axios.spread((communication_response, arrangement_response, borehole_response, seismic_response) => {
                    emit('preloader', true)                    
                    
                    communication_lines.lines = communication_response.data
                    arrangement_map.arrangement = arrangement_response.data
                    boreholes_dots.boreholes = borehole_response.data
                    seismic_lines.seismic = seismic_response.data
                }))
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
                .finally(() => {
                    emit('preloader', false)
                })
        
        return {
            communication_lines,
            arrangement_map,
            boreholes_dots,
            seismic_lines,
            objects_inner_cell,
            plane_communication,
            setCommunicationLine,
            type_communication,
            region,
            buying_map,
            map_urls,
            build_map,
            communication_setting,
            first_step,
            reload_data,
            visible_map,
            mapMapping,
            mappingCommunicationLine,
            mappingArrangement,
            mappingBorehole,
            initialMap,
            openDetailedMap,
            buildingCommunication,
            openBoreholeDetailed,
            setEndPoint,
            constructionOfCommunications,
            openSeismicMenu,
            activityOnCell,
            // mappingCommunicationLineSegment
        }    
    },
    components: {
        'detailed-cell': detailedCell,
        'communication-cmp': communicationProjection,
        'arrangement-cmp': arrangementProjection,
        'borehole-cmp': boreholeProjection,
        'seismic-cmp': seismicProjection,
        'polygon-cmp': polygonImages,
        'map-cmp': mapProjection
    },

})
