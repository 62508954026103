import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oil-map oil-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grid_cmp = _resolveComponent("grid-cmp")!
  const _component_object_cmp = _resolveComponent("object-cmp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_grid_cmp, {
      onSelected_tab: _ctx.setMenuValue,
      class: "oil-map__block"
    }, null, 8, ["onSelected_tab"]),
    _createVNode(_component_object_cmp, {
      reload_data_state: _ctx.selected_menu.state,
      class: "oil-map__block"
    }, null, 8, ["reload_data_state"])
  ]))
}