import Legend from '../src/pages/legend/legend-page.vue'
import Map from '../src/pages/map/map-page.vue'
import Card from '../src/pages/card/card-page.vue'
import Graphics from "../src/pages/graphics/graphics-page.vue"
import Results from "../src/pages/results/results-page.vue"
import Turn from "../src/pages/turn/turn-page.vue"
import Drilling from "../src/pages/drilling/drilling-page.vue"
import Research from "../src/pages/chemical_research/chemical_research.vue"
import Scene from '../src/pages/scene/scene-page-new.vue'
import Test from '../src/pages/test-page/test-page.vue'
import Statistic from '../src/pages/statistic/statistic-page.vue'
import StatisticId from "../src/pages/statistic/statistic_id/statistic-page.vue"
import Economy from './pages/economy/economy-page.vue'

const routes = [
    {
        path: '/',
        name: 'legend',
        component: Legend
    },
    {
        path: '/test',
        name: 'test',
        component: Test
    },
    {
        path: '/map',
        name: 'map',
        component: Map
    },
    {
        path: '/card/:id',
        name: 'card',
        component: Card
    },
    {
        path: '/graphics/:id',
        name: 'graphics',
        component: Graphics
    },
    {
        path: '/turn',
        name: 'turn',
        component: Turn
    },
    {
        path: '/results',
        name: 'result',
        component: Results
    },
    {
        path: '/scene',
        name: 'scene',
        component: Scene
    },
    {
        path: '/statistics',
        name: 'statistic',
        component: Statistic
    },
    {
        path: '/statistic/:id',
        name: 'statistic_id',
        component: StatisticId
    },
    // {
    //     path: '/drilling',
    //     name: 'drilling',
    //     component: Drilling
    // },
    {
        path: '/drilling',
        name: 'drilling',
        component: Drilling
    },
    {
        path: '/chemical-research/:id',
        name: 'research',
        component: Research
    },
    {
        path: '/economy',
        name: 'economy',
        component: Economy
    }
]

export default routes