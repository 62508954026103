 
import input from '../../components/ui-components/oil-input/input-cmp.vue';
import buttonCmp from "../../components/ui-components/oil-button/button-cmp.vue";
import endTableCmp from "../../components/ui-components/oil-end-table/end-table-cmp.vue";
// import axios from 'axios'
// import { mapMutations } from 'vuex';
import { useStore } from 'vuex'
import axios from 'axios';
import { useRouter } from 'vue-router'
import { defineComponent, onMounted, reactive } from 'vue'

    export default defineComponent({
        setup() {
            const store = useStore()
            const router = useRouter()

            const end_turn_table = reactive({
                table: []
            })

            const show = reactive({
                state: false
            })

            const days = reactive({
                value: 1,
                days_skip: 1
            })

            const detailedShow = () => {
                show.state = !show.state
            }

            const setValue = (val) => {
                days.value = val.value
            } 

            const setDay = (val) => {
                days.days_skip = val.value
            }

            // const calculated = () => {
            //     axios
            //         .get(`Views/Browser/Finish/Complete?nStepsToSkip=${days.days_skip}`)
            //         .then((resp) => {end_turn_table.table = resp.table})
            //         .catch(err => store.commit('notification/openNotification', err.response.data))
            // }

            const sendDays = () => {
                axios
                    .post(`api/v1/CompleteMove?nStepsToSkip=${days.days_skip}&inventoryVolume=${days.value}`)
                    .then(resp => {
                        if(resp.data) {
                            router.push('/map')
                        }
                    })
                    .catch(err => {                                         
                        store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    })
                    .finally(() => {
                        store.commit('request/setReloadState', 'maps')
                    })
            }

            onMounted(() => {
                const url = 'Views/Browser/Finish/Complete'
                axios
                    .get(url)
                    .then((resp) => {
                        end_turn_table.table = resp.data.table
                    })
                    .catch(err => store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    }))
            })

            return {
                end_turn_table,
                show,
                days,
                detailedShow,
                setValue,
                setDay,
                sendDays
            }
        },
        components: {
            'input-cmp': input,
            'button-cmp': buttonCmp,
            'end-table-cmp': endTableCmp
        },
    })
