import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_cmp = _resolveComponent("table-cmp")!
  const _component_btn_cmp = _resolveComponent("btn-cmp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${$props.main_class}__communication`)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(`${$props.main_class}__communication__title`)
    }, "Добавление коммуникаций", 2),
    _createElementVNode("p", {
      class: _normalizeClass(`${$props.main_class}__communication__text`)
    }, "Выберите тип коммуникации, который вы хотите построить. Нажмите кнопку “Начать” и выберите сначала клетку, от которой хотите провести коммуникацию (начало), а затем выберите клетку, до которой вы хотите провести коммуникацию (конец). Чтобы завершить строительство нажмите кнопку “Выйти из режима строительства”.", 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.data, (item) => {
      return (_openBlock(), _createBlock(_component_table_cmp, {
        key: item,
        view: item.type,
        content: item.content
      }, null, 8, ["view", "content"]))
    }), 128)),
    _createElementVNode("div", {
      class: _normalizeClass(`${$props.main_class}__communication__btns`)
    }, [
      _createVNode(_component_btn_cmp, {
        text: 'Отменить',
        background: false,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeModal()))
      }),
      _createVNode(_component_btn_cmp, {
        text: 'Начать',
        background: true,
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.startBuilding()))
      })
    ], 2)
  ], 2))
}