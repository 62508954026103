import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_cmp = _resolveComponent("radio-cmp")!
  const _component_button_cmp = _resolveComponent("button-cmp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.main_class}__borehole`)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(`${_ctx.main_class}__borehole__title`)
    }, _toDisplayString(_ctx.$root.static_text.modal.borehole.title), 3),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__borehole__cells`)
    }, [
      _createElementVNode("span", null, "Ячейка: x " + _toDisplayString(_ctx.$props.data.region.x) + " y " + _toDisplayString(_ctx.$props.data.region.y), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$root.static_text.modal.borehole.region) + ": " + _toDisplayString(_ctx.data.order_id), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__borehole__types`)
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$root.static_text.modal.borehole.type), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__borehole__radios`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.borehole.type_boreholes, (radio) => {
        return (_openBlock(), _createBlock(_component_radio_cmp, {
          key: radio,
          text: radio.name,
          id: radio.id,
          active: _ctx.borehole.active_type,
          onSetId: _ctx.setMapType,
          class: _normalizeClass(`${_ctx.main_class}__choose-map__radios__radio`)
        }, null, 8, ["text", "id", "active", "onSetId", "class"]))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__borehole__btns`)
    }, [
      _createVNode(_component_button_cmp, {
        text: _ctx.$root.static_text.buttons.cancel,
        background: false,
        onClick: _ctx.closeModal
      }, null, 8, ["text", "onClick"]),
      _createVNode(_component_button_cmp, {
        text: _ctx.$root.static_text.buttons.add,
        onClick: _ctx.addBoreholes
      }, null, 8, ["text", "onClick"])
    ], 2),
    _createVNode(_component_button_cmp, {
      text: _ctx.$root.static_text.buttons.well_pad,
      onClick: _ctx.deleteWellPad,
      background: false
    }, null, 8, ["text", "onClick"])
  ], 2))
}