
import { defineComponent, reactive } from 'vue'
import axios from 'axios'
import buttonCmp from "../../components/ui-components/oil-button/button-cmp.vue"

export default defineComponent({
    setup() {
        const borehole_title = reactive({
            value: '' as string
        })

        const tooltip_visible = reactive({
            value: '' as string
        })

        const table = reactive({
            header_components: [
                {
                    text: 'Ед.изм.'
                },
                {
                    text: 'H2'
                },
                {
                    text: 'He'
                },
                {
                    text: 'N2'
                },
                {
                    text: 'CO2'
                },
                {
                    text: 'CH4'
                },
                {
                    text: 'C2H6'
                },
                {
                    text: 'C3H8'
                },
                {
                    text: 'iC4H10'
                },
                {
                    text: 'nC4H10'
                },
                {
                    text: 'C5+'
                },
            ] as any,
            header_other: [
                {
                    text: 'Коэффициент А'
                },
                {
                    text: 'Коэффициент B'
                },
                {
                    text: 'Эффективная толщина, м'
                },
                {
                    text: 'Пластовая температура,°C'
                },
                {
                    text: 'Пластовое давление, атм.'
                },
                {
                    text: 'Глубина кровли, м'
                },
                {
                    text: 'Тип притока'
                },
            ] as any
        })

        const tables = reactive({
            components: [] as any,
            other: [] as any,
        })

        return {
            tables,
            table,
            borehole_title,
            tooltip_visible
        }    
    },
    components: {
        'btn-cmp': buttonCmp
    },
    methods: {
        tooltipVisible(val) {           
            this.tooltip_visible.value = val
        },
        loadResults() {
            axios
                .get(`Api/v1/Purchased/LithologicalReservoirParam/file/${this.$route.params.id}`, { responseType: 'blob' })
                .then(response_tables => {
                    console.log(response_tables);
                    
                    const href = URL.createObjectURL(response_tables.data)
                    const link = document.createElement('a')
                    link.href = href 
                    link.setAttribute('download', `${this.borehole_title.value}.csv`)
                    document.body.appendChild(link)
                    link.click()

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
        }
    },
    created() {
        axios
            .get(`Api/v1/Purchased/LithologicalReservoirParam/${this.$route.params.id}`)
            .then(response_tables => {
                console.log(response_tables);
                
                this.tables.components = response_tables.data.components
                this.tables.other = response_tables.data.other
                this.borehole_title.value = response_tables.data.boreholeName
            })
    }
})

