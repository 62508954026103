<template>
    <div class="oil-profile-modal-table" :class="{_header: view === 'header', _content:  view === 'content'}">
        <template v-if="view === 'header'">
            <span class="oil-profile-modal-table__value" v-for="item in table" :key="item.id">{{ item.text }}</span>
        </template> 
        <template v-else>
            <div v-for="item in table" :key="item.id" class="oil-profile-modal-table__content">
                <span class="oil-profile-modal-table__value">{{ item.CostType }}</span>
                <span class="oil-profile-modal-table__value">{{ item.parameterValue }}</span>
                <svg @mouseenter="tooltip = item.tooltipId" @mouseleave="tooltip = false"  class="oil-profile-modal-table-info" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#398BDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <transition name="fade">
                    <div class="oil-profile-modal-table-info-tooltip"  v-if="tooltip === item.tooltipId">
                        <span>{{ item.tooltip }}</span>
                    </div>
                </transition>
            </div>
        </template>
    </div>
</template>
<script> 
    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                tooltip: false
            }
        }
    }
</script>