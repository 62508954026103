
import { defineComponent, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router'
import axios from 'axios'

export default defineComponent({
    props: {
        table: {
            type: Object,
            default:() => ({
                header: [],
                content: []
            })
        },
    },
    setup() {
        const route = useRoute()

        const table_template = [
            {
                date: {
                    text: "Дата заказа"
                },
                boreholeStatus: {
                    text: "Статус",
                },
                boreholeResearch: {
                    text: "Исследование"
                },
                boreholeMark: {
                    text: "Обозначение"
                },
                boreholeCapex: {
                    text: "CAPEX"
                },
                boreholeTu: {
                    text: "TU"
                },
                boreholeWfu: {
                    text: "WFU"
                },
                boreholeEu: {
                    text: "EU"
                },
            },
            {
                researchName: "ГИС (Геофизические исследования)",
                researches: [
                    {
                        date: {
                            text: "25.10.2022"
                        },
                        boreholeStatus: {
                            text: "Исследование",
                            color: "#DCFFF0"
                        },
                        boreholeResearch: {
                            text: "Гамма-каротаж"
                        },
                        boreholeMark: {
                            text: "GR",
                            color: "#5581D6"
                        },
                        boreholeCapex: {
                            text: "30"
                        },
                        boreholeTu: {
                            text: "30"
                        },
                        boreholeWfu: {
                            text: "30"
                        },
                        boreholeEu: {
                            text: "30"
                        },
                    },
                    {
                        date: {
                            text: "25.10.2022"
                        },
                        boreholeStatus: {
                            text: "Исследование",
                            color: "#DCFFF0"
                        },
                        boreholeResearch: {
                            text: "Гамма-каротаж"
                        },
                        boreholeMark: {
                            text: "GR",
                            color: "#5581D6"
                        },
                        boreholeCapex: {
                            text: "30"
                        },
                        boreholeTu: {
                            text: "30"
                        },
                        boreholeWfu: {
                            text: "30"
                        },
                        boreholeEu: {
                            text: "30"
                        },
                    },
                    {
                        date: {
                            text: "25.10.2022"
                        },
                        boreholeStatus: {
                            text: "Исследование",
                            color: "#DCFFF0"
                        },
                        boreholeResearch: {
                            text: "Гамма-каротаж"
                        },
                        boreholeMark: {
                            text: "GR",
                            color: "#5581D6"
                        },
                        boreholeCapex: {
                            text: "30"
                        },
                        boreholeTu: {
                            text: "30"
                        },
                        boreholeWfu: {
                            text: "30"
                        },
                        boreholeEu: {
                            text: "30"
                        },
                    },
                ]
                
            },
        ]

        const tables = reactive({
            research: []
        })

        const open_subrow = reactive({
            id: 0
        })

        const openSubRow = (sub_row_id: number) => {
            open_subrow.id = open_subrow.id === sub_row_id ? 0 : sub_row_id
        }

        onMounted(() => {
            axios 
                .get(`/Views/Browser/Statistic/Borehole/LogName/${route.params.id}`)
                .then(response => {
                    tables.research = response.data
                })
        })

        return {
            open_subrow,
            table_template,
            tables,
            openSubRow
        }
    }
})

