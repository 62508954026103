
import { defineComponent, reactive, computed } from 'vue'
import checkboxCmp from '../../../ui-components/oil-checkbox/checkbox-cmp.vue'
import { mapMutations, mapState } from 'vuex'
import { useStore } from 'vuex'
import axios from 'axios'
import btnCmp from '../../../ui-components/oil-button/button-cmp.vue'

export default defineComponent({
    props: {
        data: {
            type: Array,
            default: () => []
        },
        main_class: {
            type: String,
        }
    },
    setup() {
        const active_type_map = reactive({
            value: 'structuralMap'
        })
        
        const maps_data = reactive({
            value: [] as Array<{
                id: string,
                description: string,
                oc: number,
                tu: number,
                isPurchased: boolean
            }>,
            selected_maps: [] as Array<{
                surfaceImageIds: string,
                description: string,
                oc: number,
                tu: number,
                checked: boolean,
                isPurchased: boolean
            }>
        })

        const has_checked_maps = computed(() => {
            return maps_data.selected_maps.some(map => map.checked)
        })

        const store = useStore()
        
        axios
            .get('Views/Browser/Map/Purchase/SurfaceImage')
            .then(response => {
                maps_data.value = response.data
                maps_data.selected_maps = maps_data.value.map(item => ({
                    description: item.description,
                    surfaceImageIds: item.id,
                    oc: item.oc,
                    tu: item.tu,
                    checked: false,
                    isPurchased: item.isPurchased
                }))
            })
            .catch(error => {
                console.error('Ошибка при загрузке данных:', error)
            })


        const openChoosesMap = () => {
            maps_data.selected_maps = maps_data.selected_maps.filter(map => map.checked)

            store.commit('modal/openModal', {
                newState: 'addModelModal',
                props: {
                    data: maps_data.selected_maps,
                    prev_modal: 'maps'
                }
            })
        }

        const createArrayMaps = (obj) => {
            if(obj.checkbox_state) {
                maps_data.selected_maps.find(item => item.description === obj.checkbox_type)!.checked = true
            } else {
                maps_data.selected_maps.find(item => item.description === obj.checkbox_type)!.checked = false
            }
        }

        return {
            active_type_map,
            openChoosesMap,
            maps_data,
            has_checked_maps,
            createArrayMaps
        }
    },
    computed: {
        ...mapState('building', ['type_map'])
    },
    components: {
        'checkbox-cmp': checkboxCmp,
        'btn-cmp': btnCmp
    },
    methods: {
        ...mapMutations('modal', ['closeModal']),
        ...mapMutations('building', ['setTypeMap', 'setBuildMap']),

        setMapType(val) {            
            this.active_type_map.value = val
            this.maps_data.selected_maps.find(map => map.surfaceImageIds === val)!.checked = true
        },
        buldingMap() {
            this.setTypeMap(this.active_type_map.value)
            this.closeModal()
        },
        closeBuildMode() {
            this.setBuildMap(false)
            this.closeModal()
        }
    }
})
