import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "oil-legend oil-container" }
const _hoisted_2 = { class: "oil-legend__menubar" }
const _hoisted_3 = { class: "oil-legend__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menubar_cmp = _resolveComponent("menubar-cmp")!
  const _component_rules_cmp = _resolveComponent("rules-cmp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$root.pages_datas.header, (item) => {
        return (_openBlock(), _createBlock(_component_menubar_cmp, {
          key: item.id,
          active: $data.active_tab,
          id: item.id,
          class: "oil-legend__menubar-item",
          text: item.text,
          onSelectTab: $options.setActiveTab
        }, null, 8, ["active", "id", "text", "onSelectTab"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$root.pages_datas.content, (item) => {
        return (_openBlock(), _createBlock(_component_rules_cmp, {
          key: item.id,
          active: $data.active_tab,
          id: item.id,
          class: "oil-legend__content-item",
          text: item.text
        }, null, 8, ["active", "id", "text"]))
      }), 128))
    ])
  ]))
}