
import { defineComponent, reactive, watch } from 'vue';
import checkboxCmp from '../../oil-checkbox/checkbox-cmp.vue';
import inputCmp from '../../oil-input/input-cmp.vue';
import axios from 'axios'
import { useStore } from 'vuex';


export default defineComponent({
    props: {
        table: {
            type: Object,
            default:() => ({
                header: [],
                content: []
            })
        },
        id: {
            type: Number,
            default: 0
        },
        price_table: {
            type: Object,
            default:() => ({
                header: [],
                content: []
            })
        }
    },
    setup(props, { emit }) {
        const store = useStore()

        const edit_row = reactive({
            state: 0
        })

        const table_data = reactive({
            general_table_data: [] as Array<{
                number: number | string,
                id: number | string,
                text: string,
                perforationId: number | string,
                startMdMeters: any,
                endMdMeters: any,
                bottomholeTreatment: any,
                fractureTreatment: any,
                perforationIsolation: any
            }>,
            price_table_data: []
        })

        const visible_tooltip = reactive({
            value: null as null | string,
        })

        const setCheckbox = (checkbox) => {
            console.log(checkbox);
            
            for(let i = 0; i < table_data.general_table_data.length; i++) {
                if(table_data.general_table_data[i].perforationId === checkbox.checkbox_id) {
                    table_data.general_table_data[i][checkbox.checkbox_type].isChecked = checkbox.checkbox_state
                }
            }
        }

        const editRow = (row_id: number) => {
            edit_row.state = row_id
        }

        const setValue = (input_value) => {
            for(let i = 0; i < table_data.general_table_data.length; i++) {
                if(table_data.general_table_data[i].perforationId === input_value.type) {
                    table_data.general_table_data[i][input_value.key].text = input_value.value
                }
            }
            
        }

        const saveState = (id) => {
            for(let i = 0; i < table_data.general_table_data.length; i++) {
                if(table_data.general_table_data[i].perforationId === id) {                    
                    axios
                        .patch('api/v1/drilling/intervention', {
                            PerforationId: id,
                            PurchasedBoreholeId: props.id,
                            StartMd: table_data.general_table_data[i].startMdMeters.text,
                            EndMd: table_data.general_table_data[i].endMdMeters.text,
                            BottomholeTreatment: table_data.general_table_data[i].bottomholeTreatment.isChecked,
                            FractureTreatment: table_data.general_table_data[i].fractureTreatment.isChecked,
                            PerforationIsolation: table_data.general_table_data[i].perforationIsolation.isChecked
                        })
                        .then((resp) => {
                            console.log(resp);
                        })
                        .finally(() => {
                            emit('reload_data', {reload: true, table_type: 'gtm'})
                            editRow(0)
                        })
                        .catch(err => {                 
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        });
                }
            }
        }

        const addPerforation = () => {
            axios
                .post(`api/v1/drilling/intervention?boreholeId=${props.id}`)
                .then((resp) => {
                    console.log(resp);
                    
                })
                .finally(() => {
                    emit('reload_data', {reload: true, table_type: 'gtm'})
                })
                .catch(err => {                 
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                });
        }

        const deleteRow = (row_id) => {
            axios 
                .delete(`/Api/V1/Drilling/Intervention/${row_id}`)
                .finally(() => {
                    emit('reload_data', {reload: true, table_type: 'gtm'})
                })
                .catch(err => {
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        watch(() => props.table, () => {            
            table_data.general_table_data = props.table.header?.content.concat(props.table.content?.content)
            table_data.general_table_data[0].number = "№"
        })

        watch(() => props.price_table, () => {
            table_data.price_table_data = props.price_table.header?.content.concat(props.price_table.content?.content)
        })

        return {
            edit_row,
            setValue,
            saveState,
            table_data,
            visible_tooltip,
            setCheckbox,
            addPerforation,
            deleteRow,
            editRow
        }
    },
    components: {
        'checkbox-cmp': checkboxCmp,
        'input-cmp': inputCmp
    }
})
