
import Menubar from "../../components/ui-components/oil-menubar/menubar-cmp.vue"
import axios from "axios"
import constructTable from "../../components/ui-components/oil-table-drilling/construct-table/construct-table.vue"
import profileTable from "../../components/ui-components/oil-table-drilling/profile-table/profile-table.vue"
import gtmTable from "../../components/ui-components/oil-table-drilling/gtm-table/gtm-table.vue"
import selectInput from "../../components/ui-components/oil-select-input/oil-select-input.vue"
import researchCmp from "../../components/ui-components/oil-table-drilling/research-table/research-table.vue"
import { defineComponent, onMounted, reactive, watch } from 'vue'
import { useRouter } from "vue-router"
import { useStore } from 'vuex'

export default defineComponent({
    setup() {
        const store = useStore()
        const router = useRouter()
        
        const borehole_list = reactive({
            list: [
                {
                    name: 'Borehole',
                    id: 0,
                    boreholeType: ''
                }
            ] as Array<{
                name: string,
                id: number,
                boreholeType: string
            }>
        })

        const tabs_menu = reactive({
            menubar_list: [
                {
                    text: "Профиль",
                    id: "Profile",
                },
                {
                    text: "Конструкция",
                    id: "Construction"
                },
                {
                    text: "Исследования",
                    id: "Research"
                },
                {
                    text: "ГТМ",
                    id: "GTM"
                },
            ],
            active_tab: 'Profile'
        })

        const selected_borehole = reactive({
            value: 0 as number
        })
        
        const boreholes_data = reactive({
            boreholes_profile_data: {
                header: {
                    type: '',
                    content: []
                },
                content: {
                    type: '',
                    content: []
                }
            },
            boreholes_construction_data: {},
            boreholes_research_data: {},
            boreholes_intervention_data: {},
            boreholes_intervention_prices_data: {},
            borehole_change: false
        })

        const update_state = reactive({
            state: false
        })

        const research_type = reactive({
            value: 'WellLog'
        })

        const borehole_type = reactive({
            value: ''
        })

        const updateTable = (reload_data) => {
            update_state.state = reload_data.true

            switch (reload_data.table_type) {
                case 'profile':
                    axios
                        .get(endpoints_data_drilling.boreholes_main_data_endpoint()[0])
                        .then((response_profile) => {
                            boreholes_data.boreholes_profile_data = response_profile.data
                        })
                        .finally(() => {
                            update_state.state = false
                        })
                        .catch(err => {
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        })
                    break;
                                    
                case 'construct':                 
                    axios
                        .get(endpoints_data_drilling.boreholes_main_data_endpoint()[1])
                        .then((response_construt) => {
                            boreholes_data.boreholes_construction_data = response_construt.data
                            boreholes_data.borehole_change = response_construt.data.isChangeable
                        })
                        .finally(() => {
                            update_state.state = false
                        })
                        .catch(err => {
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        })
                    break;
                                    
                case 'research':                 
                    axios
                        .get(endpoints_data_drilling.boreholes_main_data_endpoint()[2])
                        .then((response_research) => {
                            boreholes_data.boreholes_research_data = response_research.data
                        })
                        .finally(() => {
                            update_state.state = false
                        })
                        .catch(err => {
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        })
                    break;

                case 'gtm':
                    axios
                        .get(endpoints_data_drilling.boreholes_main_data_endpoint()[3])
                        .then((response_intervention) => {
                            boreholes_data.boreholes_intervention_data = response_intervention.data
                        })
                        .finally(() => {
                            update_state.state = false
                        })
                        .catch(err => {
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        })
                    break;

                default:
                    break;
            }
        }

        const endpoints_data_drilling = reactive({
            boreholes_list_endpoint: "Views/Browser/Map/Borehole/Info",
            boreholes_main_data_endpoint() {
                return [
                    `Views/Browser/Drilling/Profile?id=${selected_borehole.value}`,
                    `Views/Browser/Drilling/Construction?boreholeId=${selected_borehole.value}`,
                    `Views/Browser/Drilling/Research/${selected_borehole.value}?logNameGroup=${research_type.value}`,
                    `Views/Browser/Drilling/intervention?boreholeId=${selected_borehole.value}`,
                    `Views/Browser/Drilling/intervention/prices`
                ]
            }
        })

        const boreholeSelection = (borehole_id: {id: number, type: string}) => {
            selected_borehole.value = borehole_id!.id

            borehole_type.value = borehole_id.type
        }
        const setResearchType = (type) => {
            research_type.value = type
        }

        const getDataBorehole = () => {
             axios.all(
                endpoints_data_drilling.boreholes_main_data_endpoint().map((endpoint: string) => {
                    return axios.get(endpoint);
                })
            )
            .then(axios.spread((response_profile, response_construct, response_research, response_intervention, response_intervention_prices) => {
                boreholes_data.boreholes_profile_data = response_profile.data
                boreholes_data.boreholes_construction_data = response_construct.data
                boreholes_data.boreholes_research_data = response_research.data
                boreholes_data.boreholes_intervention_data = response_intervention.data
                boreholes_data.boreholes_intervention_prices_data = response_intervention_prices.data
            }))
            .catch(err => {                 
                store.commit('notification/openNotification', {
                    error: true,
                    title: 'Ошибка!',
                    desc: err.response.data
                })
            });

        }

        const selectMenu = (menu_id: string) => {
            tabs_menu.active_tab = menu_id
        }

        watch(() => selected_borehole.value, () => {
            getDataBorehole();

            router.push({ 
                path: router.currentRoute.value.path,
                query: { id: selected_borehole.value }
            });            
        })

        watch(() => research_type.value, () => {
            getDataBorehole()
        })

        onMounted(() => {
            axios
                .get(endpoints_data_drilling.boreholes_list_endpoint)
                .then((response_list) => {
                    borehole_list.list = response_list.data.items
                })
                .finally(() => {
                    selected_borehole.value = borehole_list.list[0].id
                    borehole_type.value = borehole_list.list[0].boreholeType
                })
                .catch(err => {
                    store.commit('notification/openNotification', {
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        })

        return {
            borehole_list,
            boreholeSelection,
            selected_borehole,
            boreholes_data,
            updateTable,
            tabs_menu,
            selectMenu,
            setResearchType,
            research_type,
            borehole_type
        }
        
    },
    data() {
        return {
            active: "Profile",
            perforation: false,
            open_face: true,
            menubar: [
                {
                    text: "Профиль",
                    id: "Profile"
                },
                {
                    text: "Конструкция",
                    id: "Construction"
                },
                {
                    text: "Исследования",
                    id: "Research"
                },
                {
                    text: "ГТМ",
                    id: "GTM"
                },
            ],
            level: 1,
            boreholeId: 0,
            table: [],
            construct: [],
            research: [],
            profile: [],
            gtm: [],
            size: 0,
            list: {
                items: []
            },
            selectInputVisible: false,
            visible: true
        }
    },
    components: {
        'menubar-cmp': Menubar,
        'construct-table': constructTable,
        'profile-table': profileTable,
        'select-input': selectInput,
        'research-table': researchCmp,
        'gtm-table': gtmTable
    }
})
