<template>
    <transition name="fadeRight">
        <div class="oil-notification" :class="{_success: notificationProps.success, _error: notificationProps.error, _warning: notificationProps.warning}" v-if="notificationProps" :props="notificationProps">
            <svg class="oil-notification__close" @click="closeNotification" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 7L7 17M7 7L17 17" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="oil-notification__title">{{ notificationProps.title }}</span>
            <span class="oil-notification__desc">{{ notificationProps.desc }}</span>
        </div>
    </transition>
</template>
<script> 
import { mapMutations } from 'vuex';

    export default {
        computed: {
            notificationProps() {
                return this.$store.state.notification.notificationProps;
            },
        },
        data() {
            return {
                visible: true,
                hover: false,
                timer_id: null
            }
        },
        methods: {
            ...mapMutations('notification', ['closeNotification'])
        },
        watch: {
            // notificationProps() {
            //     console.log(this.$store.state.notification.notificationProps);
            // },
            notificationProps() {
                console.log(this.$store.state.notification.notificationProps)
                clearTimeout(this.timer_id)
                this.timer_id = setTimeout(() => {
                    this.closeNotification()  
                }, 5000);
            }
        },
    }
</script>