export default {
    namespaced: true,
    state: {
        build: false,
        typeCommunication: '',
        build_map: false,
        type_map: '',
        clear_coords: false,
        visibleMap: 'satellitePhoto'
    },
    getters: {
        build(state) {
            return state.build
        },
        build_map(state) {
            return state.build_map
        },
        typeCommunication(state) {
            return state.typeCommunication
        },
        type_map(state) {
            return state.type_map
        },
    },
    mutations: {
        setBuilding(state, val) {
            state.build = val
        },
        setBuildMap(state, val) {
            state.build_map = val
        },
        setTypeCommunication(state, val) {
            state.typeCommunication = val
        },
        setTypeMap(state, val) {
            state.type_map = val
        },
        visibleMapState(state, val) {
            state.visibleMap = val
        },
        updateBuildMap(state, val) {
            console.log(val);
            
            state.clear_coords = val
        }
    }
}