
import tableCmp from './table-cmp/table-cmp.vue'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import radioCmp from "../../../ui-components/oil-radio/radio-cmp.vue"
import axios from 'axios'
import { defineComponent, reactive } from 'vue'
import { useStore } from "vuex"

interface SeismicData {
    coords: {
        x: number;
        y: number;
    };
}

export default defineComponent({
    props: {
        data: {
            type: Object as () => SeismicData,
            default: () => ({ coords: { x: 0, y: 0 } })
        },
        main_class: {
            type: String,
        },
        GlobalLocalization: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const store = useStore()

        const seismic = reactive({
            seismic_list: [
                { id: 1, text: 'Север-Юг' },
                { id: 2, text: 'Восток-Запад'}
            ],
        })

        const active_seismic = reactive({
            value: 1
        })

        const closeModal = () => {
            store.commit('modal/closeModal')
        }

        const setSeismicType = (radio_id: number) => {
            active_seismic.value = radio_id
        }


        const setLine = () => {
            const url = 'api/v1/Purchased/Seismic'

            const coords = props.data.coords
            const startX = coords ? coords.x : 0
            const startY = coords ? coords.y : 0

            const isVertical = active_seismic.value === 1

            axios.post(url, {
                StartX: isVertical ? startX : 1,
                StartY: isVertical ? 1 : startY,
                EndX: isVertical ? startX : 25,
                EndY: isVertical ?  25 : startY,
            }).then(() => {
                store.commit('request/setReloadState', 'seismic')
                closeModal()
            }).catch(err => {                 
                store.commit('notification/openNotification', {
                    error: true,
                    title: 'Ошибка!',
                    desc: err.response.data
                })
            })
        }

        return {
            seismic,
            active_seismic,
            closeModal,
            setSeismicType,
            setLine
        }
    },
    components: {
        'table-cmp': tableCmp,
        'button-cmp': buttonCmp,
        'radio-cmp': radioCmp
    }
})
