
import { defineComponent, onMounted, reactive, watch } from 'vue'
import { useStore, mapMutations } from 'vuex'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import axios from 'axios'

export default defineComponent ({
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        main_class: {
            type: String
        }
    },
    setup (props) {

        const selected_models = reactive({
            value: [] as any
        })

        const idList = reactive({
            value: [] as any
        })

        const model_data = reactive({
            data: [] as any
        })

        const store = useStore()

        const current_date = reactive({
            value: ''
        })

        // Состояния предыдущих модальных окон
        const is3DModel = props.data.prev_modal === '3d_model'
        const isMaps = props.data.prev_modal === 'maps'

        const closeModal = () => {
            store.commit('modal/closeModal')
        }

        const returnPreviousModal = () => {
            if(is3DModel) {
                store.commit('modal/openModal', {
                        newState: 'threeDModal'
                    }
                )
            } else if(isMaps) {
                store.commit('modal/openModal', {
                        newState: 'chooseMapModal'
                    }
                )
            }
        }

        const addModel = () => {
            selected_models.value = props.data
            
            if(is3DModel) {
                idList.value = selected_models.value.data.filter((model: any) => model.checked).map(model => model.propertyId)
                axios
                    .post('api/v1/Purchased/ModelProperty', {
                        PropertyIdList: idList.value
                    })
                    .finally(() => {
                        store.commit('request/setReloadState', 'Model3D')
                        closeModal()
                    })
            } else if (isMaps) {
                idList.value = selected_models.value.data.filter((model: any) => model.checked).map(model => model.surfaceImageIds)
                axios
                    .post('/api/v1/purchased/SurfaceImage', {
                        surfaceImageIds: idList.value
                    })
                    .finally(() => {
                        store.commit('request/setReloadState', 'maps')
                        closeModal()
                    })
            }
        }

        onMounted(() => {
            model_data.data = props.data
            axios
                .get('Views/Browser/Navigation/Header')
                .then(response_date => current_date.value = response_date.data.currentDate)
        })


        watch(() => model_data.data, () => {
            model_data.data
        })

        return {
            closeModal,
            returnPreviousModal,
            addModel,
            selected_models,
            current_date,
            idList,
            model_data,
            is3DModel,
            isMaps
        }
    },
    components: {
        'button-cmp': buttonCmp
    },
    methods: {
        ...mapMutations('modal', ['openModal'])
    }
})
