<template>
    <transition name="fade">
            <div class="oil-modal" v-if="modalComponent" :key="modalComponent">
                <div v-if="modalComponent !== 'authModal' && modalComponent !== 'restoreModal'" @click="$root.closeModal()" class="oil-modal__shadow"></div>
                <div class="oil-modal__container">
                    <keep-alive :include="cachedComponents" :key="modalComponent">
                        <component :is="modalComponent" :data="modalProps" :main_class="'oil-modal'"></component>
                    </keep-alive> 
                </div>
            </div>
    </transition>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import authModal from './auth-modal/auth-modal-refactor.vue'
    import mapModal from './map-modal-new/map-modal.vue'
    import seismicModal from './seismic-modal/seismic-modal.vue'
    import restoreModal from './restore-modal/restore-modal-refactor.vue'
    import boreholeModal from './borehole-modal/boreholes-modal.vue'
    import polygonModal from './polygon-modal/polygon-modal.vue'
    import buildMapModal from './build-map-modal/build-map-modal.vue'
    import chooseMapModal from './choose-map-modal/choose-map-modal.vue'
    import otherModal from './other-modal/other-modal.vue'
    import levelsModal from './levels-modal/levels-modal.vue'
    import communicationModal from './communication-modal/communication-modal.vue'
    import editIntervalMoval from './edit-interval-modal/edit-interval-modal.vue'
    import statusModal from './status-modal/status-modal.vue'
    import threeDModal from './3d-modal/3d-modal.vue'
    import addModelModal from './addModel-modal/addModel-modal.vue'

    export default {
        data() {
            return {
                modalShadow: true,
                curtain: true
            }
        },
        components: {
            authModal,
            mapModal,
            seismicModal,
            restoreModal,
            boreholeModal,
            polygonModal,
            buildMapModal,
            chooseMapModal,
            levelsModal,
            otherModal,
            communicationModal,
            editIntervalMoval,
            statusModal,
            threeDModal,
            addModelModal
        },
        computed: {
            ...mapGetters('modal', ['modalComponent', 'modalProps', 'modalShadow']),

            cachedComponents() {
                if (!this.modalComponent) {
                    return [];
                }
                return [
                    this.modalComponent
                ];
            }
        },
        watch: {
            modalShadow() {
                this.$emit('shadow', this.modalShadow)
            },
            curtain() {
                this.$emit('curtain', this.curtain)
            },
        },
        methods: {
            ...mapMutations('modal', ['closeModal']),

            setModalShadow(val) {
                this.modalShadow = val
            },
            setCurtainState(val) {
                this.curtain = val
            }
        },
    }
</script>./edit-interval-modal/edit-interval-modal.vue