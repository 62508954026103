<template>
    <div class="oil-table-levels">
        <div class="oil-table-levels__container">
            <!-- <div class="oil-table-levels__row" v-for="item in table" :key="item">
                <span class="oil-table-levels__row-cell _header">{{ item.text }}</span>
            </div> -->
        </div>
        <div class="oil-table-levels__row" v-for="item in content" :key="item">
            <span class="oil-table-levels__row-cell _table">{{ item.parameter }}</span>
            <span class="oil-table-levels__row-cell _table">{{ item.value }}</span>
        </div>
    </div>
</template>
<script> 
    export default {
        props: {
            content: {
                type: Array,
                default: () => []
            }
        },
        created() {
            console.log(this.$props.content);
        }
    }
</script>