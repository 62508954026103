 
import inputCmp from "../../../ui-components/oil-input/input-cmp.vue"
import buttonCmp from "../../../ui-components/oil-button/button-cmp.vue"
import tableCmp from "./table-levels/table-levels.vue"
import axios from "axios"
import { defineComponent, reactive } from "vue"
import { mapMutations } from 'vuex'

    export default defineComponent({
        props: {
            main_class: {
                type: String,
            },
            data: {
                type: Object,
                default: () => {}
            }
        },
        setup() {
            const name = reactive({
                value: ''
            })

            const table = reactive({
                content: [] as Array<{
                    parameter: String,
                    value: Number,

                }>,
                nearestWellTopId: null as Number | null
            })

            const color_level = reactive({
                color: '#c0f' as String
            })

            const colors_array = reactive([
                {
                    color: '#004d98'
                },
                {
                    color: '#f08988'
                },
                {
                    color: '#c0f'
                },
                {
                    color: '#323c46'
                },
                {
                    color: '#f5d90c'
                }
            ])

            return {
                name,
                table,
                color_level,
                colors_array
            }

        },
        components: {
            'input-cmp': inputCmp,
            'table-cmp': tableCmp,
            'button-cmp': buttonCmp
        },
        methods: {
            ...mapMutations('modal', ['openModal', 'closeModal']),
            ...mapMutations('request', ['updateMap']),
            ...mapMutations('notification', ['openNotification']),

            setName(val) {
                this.name = val.value
            },
            changeColor() {
                this.color_level.color = this.colors_array[Math.floor(Math.random() * this.colors_array.length)].color
            },
            addLevel() {
                axios
                    .post('api/v1/Purchased/WellTop', {
                        PurchasedBoreholeId: this.$route.params.id,
                        Name: this.name,
                        color: this.color_level.color,
                        Z: this.$props.data.dots
                    })
                    .finally(() => {
                        this.closeModal()
                        this.updateMap('levels')
                    })
                    .catch(err => {
                        this.openNotification({
                            props: {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            }
                        })
                    })
            },
            deleteTopId() {
                axios
                    .delete(`api/v1/Purchased/WellTop/${this.table.nearestWellTopId}`)
                    .then(resp => {
                        console.log(resp);
                    })
                    .finally(() => {
                        this.closeModal()
                    })
            }
        },
        created() {
            const url = `Views/Browser/Result/WellTop/Add?boreholeId=${this.$route.params.id}&z=${this.$props.data.dots}`
            axios
                .get(url)
                .then((resp) => {                    
                    this.table.content = resp.data.content
                    this.table.nearestWellTopId = resp.data.nearestWellTopId
                })
                .finally(() => {

                })
            
            // axios
            //     .get('api/v1/Purchased/WellTop')
            //     .then((resp) => {
            //         console.log(resp.data);
            //         // this.table = [resp.data.header, resp.data.content]
            //     })
        }
    })
